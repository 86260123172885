import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddPhysicalProperties from './AddPhysicalProperties'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function PhysicalPropertiesList() {
    const [physicalPropertiesData, setPhysicalPropertiesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedcomponentId, setSelectedcomponentId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchPhysicalPropertiesData();
    }, []);

    const fetchPhysicalPropertiesData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/composition/physical-properties/all`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPhysicalPropertiesData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Device data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/composition/add-physical-properties?componentId=${id}`);
    };

    const handleDelete = async (componentId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Ghg Factor: ${componentId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/composition/physical-properties/delete/${componentId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchPhysicalPropertiesData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = physicalPropertiesData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Component ID': item.componentId,
            'IUPAC Name': item.iupacName,
            'Common Name': item.commonName,
            'Eqpt Code': item.eqptCode,
            'C Atoms': formatWithIndianThousandsSeparatorForExportData(item.cAtoms ? item.cAtoms.toFixed(0) : item.cAtoms),
            'H Atoms': formatWithIndianThousandsSeparatorForExportData(item.hAtoms ? item.hAtoms.toFixed(0) : item.hAtoms),
            'Mol. Wt g/mol': formatWithIndianThousandsSeparatorForExportData(item.mwGmol ? item.mwGmol.toFixed(1) : item.mwGmol.toFixed(1)),
            'Melting Point DegC': formatWithIndianThousandsSeparatorForExportData(item.meltingPointDegC ? item.meltingPointDegC.toFixed(1) : item.meltingPointDegC.toFixed(1)),
            'Boiling Point DegC': formatWithIndianThousandsSeparatorForExportData(item.boilingPointDegC ? item.boilingPointDegC.toFixed(1) : item.boilingPointDegC.toFixed(1)),
            'Density At 20 DegC/Gm': formatWithIndianThousandsSeparatorForExportData(item.densityAt20DegCGmPerMl ? item.densityAt20DegCGmPerMl.toFixed(4) : item.densityAt20DegCGmPerMl.toFixed(4)),
            'Flash Point DegC': formatWithIndianThousandsSeparatorForExportData(item.flashPointDegC ? item.flashPointDegC.toFixed(1) : item.flashPointDegC.toFixed(1)),
            'Auto Ignition Temp DegC': formatWithIndianThousandsSeparatorForExportData(item.autoIgnitionTempDegC ? item.autoIgnitionTempDegC.toFixed(1) : item.autoIgnitionTempDegC.toFixed(1)),
            'GHV BTU SCF': formatWithIndianThousandsSeparatorForExportData(item.ghvBtuScf ? item.ghvBtuScf.toFixed(0) : item.ghvBtuScf),
            'LHC BTU SCF': formatWithIndianThousandsSeparatorForExportData(item.lhvBtuScf ? item.lhvBtuScf.toFixed(0) : item.lhvBtuScf),
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, physicalPropertiesData]);

    const columns = [
        { name: 'Component ID', selector: row => row.componentId, sortable: true, resizable: true, pinned: 'left' },
        { name: 'IUPAC Name', selector: row => row.iupacName, sortable: true, resizable: true },
        { name: 'Common Name', selector: row => row.commonName, sortable: true, resizable: true },
        { name: 'Eqpt Code', selector: row => row.eqptCode, sortable: true, resizable: true },
        {
            name: 'C Atoms',
            selector: row => row.cAtoms,
            cell: row => formatWithIndianThousandsSeparator(row.cAtoms ? row.cAtoms.toFixed(0) : row.cAtoms),
            sortable: true,
        },
        {
            name: 'H Atoms',
            selector: row => row.hAtoms,
            cell: row => formatWithIndianThousandsSeparator(row.hAtoms ? row.hAtoms.toFixed(0) : row.hAtoms),
            sortable: true,
            resizable: true
        },
        {
            name: 'Mol. Wt g/mol',
            selector: row => row.mwGmol,
            cell: row => formatWithIndianThousandsSeparator(row.mwGmol ? row.mwGmol.toFixed(1) : row.mwGmol),
            sortable: true,
            resizable: true
        },
        {
            name: 'Melting Point DegC',
            selector: row => row.meltingPointDegC,
            cell: row => formatWithIndianThousandsSeparator(row.meltingPointDegC ? row.meltingPointDegC.toFixed(1) : row.meltingPointDegC.toFixed(1)),
            sortable: true,
            resizable: true
        },
        {
            name: 'Boiling Point DegC',
            selector: row => row.boilingPointDegC,
            cell: row => formatWithIndianThousandsSeparator(row.boilingPointDegC ? row.boilingPointDegC.toFixed(1) : row.boilingPointDegC.toFixed(1)),
            sortable: true,
            resizable: true
        },
        {
            name: 'Density At 20 DegC/Gm',
            selector: row => row.densityAt20DegCGmPerMl,
            cell: row => formatWithIndianThousandsSeparator(row.densityAt20DegCGmPerMl ? row.densityAt20DegCGmPerMl.toFixed(4) : row.densityAt20DegCGmPerMl.toFixed(4)),
            sortable: true,
            resizable: true
        },
        {
            name: 'Flash Point DegC',
            selector: row => row.flashPointDegC,
            cell: row => formatWithIndianThousandsSeparator(row.flashPointDegC ? row.flashPointDegC.toFixed(1) : row.flashPointDegC.toFixed(1)),
            sortable: true,
            resizable: true
        },
        {
            name: 'Auto Ignition Temp DegC',
            selector: row => row.autoIgnitionTempDegC,
            cell: row => formatWithIndianThousandsSeparator(row.autoIgnitionTempDegC ? row.autoIgnitionTempDegC.toFixed(1) : row.autoIgnitionTempDegC.toFixed(1)),
            sortable: true,
            resizable: true
        },
        {
            name: 'GHV BTU SCF',
            selector: row => row.ghvBtuScf,
            cell: row => formatWithIndianThousandsSeparator(row.ghvBtuScf ? row.ghvBtuScf.toFixed(0) : row.ghvBtuScf),
            sortable: true,
            resizable: true
        },
        {
            name: 'LHC BTU SCF',
            selector: row => row.lhvBtuScf,
            cell: row => formatWithIndianThousandsSeparator(row.lhvBtuScf ? row.lhvBtuScf.toFixed(0) : row.lhvBtuScf),
            sortable: true,
            resizable: true
        },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.componentId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.componentId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Physical_Properties_${new Date().toLocaleDateString()}`;

    const title = "Properties-Gas Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Properties-Gas</h1>
                <ul>
                    <li>System Configuration</li>
                    <li>Composition</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Physical Properties</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/composition/add-physical-properties" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}



