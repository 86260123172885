import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { DateContext } from '../../../../../contexts/DateContext';
import './FacilityEmissionGraph.css'; // Ensure to create and import your CSS file
import moment from 'moment';
import { API_BASE_URL } from '../../../../../config';

const FacilityEmissionGraph = () => {
  const { selectedDate } = useContext(DateContext);
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const fromDate = moment(selectedDate).format('YYYY-MM-DD');
      const toDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
      fetchData(fromDate, toDate);
    }
  }, [selectedDate]);

  const fetchData = async (fromDate, toDate) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/reports/EMSFacilityEmissions?fromDate=${fromDate}&toDate=${toDate}&facilityId=-1`);
      const emissionsData = response.data;
      const transformedData = transformData(emissionsData);
      initDataset(transformedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const transformData = (emissionsData) => {
    const groupedData = {};

    emissionsData.forEach(item => {
      if (!groupedData[item.facilityId]) {
        groupedData[item.facilityId] = {
          reason: item.facilityName, // Assuming facilityName as reason description
          percentage: 0, // You'll calculate the percentage based on total emissions later
          pollutants: []
        };
      }

      const existingPollutant = groupedData[item.facilityId].pollutants.find(p => p.key === item.pollutant);
      if (existingPollutant) {
        existingPollutant.value += item.emissions;
      } else {
        groupedData[item.facilityId].pollutants.push({
          key: item.pollutant,
          value: item.emissions
        });
      }
    });

    // Calculate total emissions
    const totalEmissions = emissionsData.reduce((sum, item) => sum + item.emissions, 0);

    // Calculate percentages
    for (const facilityId in groupedData) {
      const facility = groupedData[facilityId];
      const facilityEmissions = facility.pollutants.reduce((sum, p) => sum + p.value, 0);
      facility.percentage = (facilityEmissions / totalEmissions) * 100;
    }

    return Object.values(groupedData);
  };

  // const initDataset = (emissionByReasons) => {
  //   const formattedData = getFormattedData(emissionByReasons);

  //   const datasets = formattedData.datasets.map(pollutant => ({
  //     label: pollutant.key,
  //     data: pollutant.values,
  //     backgroundColor: pollutant.color,
  //     borderColor: pollutant.color,
  //   }));

  //   setData({
  //     labels: formattedData.labels,
  //     datasets
  //   });
  // };

  const initDataset = (emissionByReasons) => {
    const formattedData = getFormattedData(emissionByReasons);
  
    const datasets = formattedData.datasets.map(pollutant => ({
      label: formatPollutantLabel(pollutant.key), // Use formatPollutantLabel function to update labels
      data: pollutant.values,
      backgroundColor: pollutant.color,
      borderColor: pollutant.color,
    }));
  
    setData({
      labels: formattedData.labels,
      datasets
    });
  };
  
  // Function to format pollutant labels
  const formatPollutantLabel = (pollutantKey) => {
    switch (pollutantKey) {
      case 'NOX':
        return 'NOx';
      case 'SOX':
        return 'SOx';
      default:
        return pollutantKey;
    }
  };
  

  const getFormattedData = (emissions) => {
    const formattedData = {
      labels: [],
      datasets: []
    };

    emissions.forEach(e => {
      formattedData.labels.push(e.reason);

      e.pollutants.forEach(pollutant => {
        let dataset = formattedData.datasets.find(d => d.key === pollutant.key);

        if (!dataset) {
          dataset = {
            key: pollutant.key,
            values: [],
            color: getPollutantColor(pollutant.key) // a function to assign color based on pollutant
          };
          formattedData.datasets.push(dataset);
        }

        dataset.values.push(pollutant.value);
      });
    });

    return formattedData;
  };

  const getPollutantColor = (pollutant) => {
    const colorMap = {
      NO: '#1f77b4',
      NO2: '#2ca02c',
      NOX: '#ff7f0e',
      CO: '#d62728',
      H2S: '#9467bd',
      SOX: '#8c564b',
      CO2e: '#e377c1',
      O3: '#e377c2',
      'PM2.5': '#7f7f7f',
      PM10: '#bcbd22',
    };

    return colorMap[pollutant] || '#17becf';
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        display: false
      }
    },
    tooltips: {
      displayColors: true
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Emissions (kg)',
        },
      },
      y: {
        ticks: {
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          },
          fontColor: '#000000', // Custom font color for y-axis ticks
          beginAtZero: true
        },
        grid: {
          display: false,
        },
      }
    }
  };
  
  return (
    <div className="facility-emission-graph">
      <div className='card'>
        <div className='card-header'>
          Facility Emission
        </div>
        <div className='card-body'>
          {data.labels.length === 0 ? (
            <div className="alert alert-info">No data available for the selected date range!</div>
          ) : (
            <div className="facility-emission-graph-chart">
              <Bar data={data} options={chartOptions} height={600} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FacilityEmissionGraph;
