// GhgFactorService.js
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export const getAllGhgFactors = async () => {
  const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor`, {
    method: 'GET',
    headers,
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to fetch GHG factors');
  return await response.json();
};

export const canDeleteGhgFactor = async (factorId) => {
  const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor/can-delete/${factorId}`, {
    method: 'GET',
    headers,
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to check if GHG factor can be deleted');
  return await response.json();
};

export const deleteGhgFactor = async (factorId) => {
  const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor/delete/${factorId}`, {
    method: 'DELETE',
    headers,
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to delete GHG factor');
  return await response.json();
};

export const getGhgFactorById = async (factorId) => {
  const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor/${factorId}`, {
    method: 'GET',
    headers,
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to fetch GHG factor');
  return await response.json();
};

export const saveGhgFactor = async (ghgFactor, isEdit) => {
  const apiEndpoint = isEdit ? `${API_BASE_URL}/ghg-master/ghg-factor/update` : `${API_BASE_URL}/ghg-master/ghg-factor/save`;
  const method = isEdit ? 'PUT' : 'POST';

  const response = await fetch(apiEndpoint, {
    method,
    headers,
    credentials: 'include',
    body: JSON.stringify(ghgFactor)
  });

  if (!response.ok) throw new Error(`Failed to ${isEdit ? 'update' : 'save'} GHG factor`);
  return await response.json();
};

export const getAllUOMs = async () => {
  const response = await fetch(`${API_BASE_URL}/device/uoms`, {
    method: 'GET',
    // headers,
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to fetch UOMs');
  return await response.json();
};

export const getYears = async () => {
  let lstYears = [];
  let startYear = 1982;
  while (startYear <= 2050) {
    lstYears.push(startYear);
    startYear++;
  }
  return lstYears;
};

export const filterScopesFromAllCodeMasters = (codeMasters) => {
  return codeMasters.filter(x => x.type === CodeMasterType.Scope);
};
