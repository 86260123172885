import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmsService from '../services/EmsService';
import Loader from '../../../../common/loader/Loader';
import './PollutantSummary.css'; // Ensure to import your CSS file
import { formatNumberDashboard } from '../../../../common/FormatNumber';

const PollutantSummary = ({ areaId, facilityId, date, pollutantStats, setPollutantStats }) => {
  const [loading, setLoading] = useState(false);
  const [hoveredPollutant, setHoveredPollutant] = useState(null);

  useEffect(() => {
    if (areaId && facilityId && date) {
      fetchPollutantStats();
    }
  }, [areaId, facilityId, date]);

  const fetchPollutantStats = async () => {
    setLoading(true);
    try {
      const response = await EmsService.getPollutantStats(areaId, facilityId, date);
      setPollutantStats(response.data);
    } catch (error) {
      console.error('Error fetching pollutant stats', error);
    } finally {
      setLoading(false);
    }
  };

  const formatPollutantLabel = (pollutantId) => {
    switch (pollutantId) {
      case 'NOX':
        return 'NOx';
      case 'SOX':
        return 'SOx';
      default:
        return pollutantId;
    }
  };

  return (
    <div className="pollutant-summary ml-4 mr-4">
      {loading ? (
        <Loader />
      ) : (
        <div className='card'>
          <div className='card-body'>
            <div className="row">
              {pollutantStats.map((item) => (
                <div
                  key={item.pollutantId}
                  className="col-sm-2 col-md-2 pollutant-series"
                  onMouseEnter={() => setHoveredPollutant(item.pollutantId)}
                  onMouseLeave={() => setHoveredPollutant(null)}
                >
                  <div className={`pollutant-tile ${item.pollutantId}`}>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className={`pollutant-name ${item.pollutantId}`}>
                          {formatPollutantLabel(item.pollutantId)} {/* Format pollutant label here */}
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className="monthly-emission">
                          <h3>{formatNumberDashboard(item.monthlyEmission.toFixed(0))} ({item.monthlyPercentage.toFixed(2)}%)</h3>
                        </div>
                      </div>
                    </div>

                    {hoveredPollutant === item.pollutantId && (
                      <div className="pollutant-tooltip">
                        <div className="annual-emission">
                          <h3>Annual: {formatNumberDashboard(item.annualEmission.toFixed(0))} ({item.annualPercentage.toFixed(2)}%)</h3>
                        </div>
                        <div className="violations">
                          <h3>Violations: {formatNumberDashboard(item.violations.toFixed(0))}</h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


PollutantSummary.propTypes = {
  areaId: PropTypes.string.isRequired,
  facilityId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  pollutantStats: PropTypes.array.isRequired,
  setPollutantStats: PropTypes.func.isRequired,
};

export default PollutantSummary;
