import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import AmtDashboardService from '../services/AmtDashboardService';
import { DateContext } from '../../../../../contexts/DateContext';

const AmtDashboard = () => {
  const { selectedDate } = useContext(DateContext);
  const [dashboardData, setDashboardData] = useState({ stats: {}, hourlyAverages: [] });
  const [averageChartData, setAverageChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [combinedChartLabels, setCombinedChartLabels] = useState([]);
  const [plantNames, setPlantNames] = useState([]);
  const [pollutants, setPollutants] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState('');
  const [selectedPollutant, setSelectedPollutant] = useState('');

  const [dailyMaxData, setDailyMaxData] = useState({}); // State for storing daily max data

  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6321', '#36A2DE', '#FFCE83', '#4BC0C9', '#A020F0'];

  const colorstwo = ['red', 'blue', 'green', 'yellow', 'purple', 'violet', 'cyon', 'grey', 'orange', '#4BC0C9', '#A020F0'];


  const formatPollutantLabel = (pollutantCode) => {
    switch (pollutantCode) {
      case 'NOX':
        return 'NOx';
      case 'SOX':
        return 'SOx';
      default:
        return pollutantCode;
    }
  };
  

  useEffect(() => {
    if (selectedDate) {
      const datePart = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      resetDashboardData();
      fetchDashboardData(datePart);
    }
  }, [selectedDate]);

  const fetchDashboardData = async (date) => {
    try {
      const [statsResp, hourlyAvgResp] = await Promise.all([
        AmtDashboardService.getStats(date),
        AmtDashboardService.getHourlyAvgForMonth(date)
      ]);

      const combinedData = {
        stats: statsResp.data,
        hourlyAverages: hourlyAvgResp.data,
      };

      setDashboardData(combinedData);

      // Process data for both bar and line charts
      processChartData(combinedData.hourlyAverages);
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    }
  };

  const processChartData = (data) => {
    const plantPollutantMap = {};
    const dailyMaxData = {};

    const uniquePlantNames = new Set();
    const uniquePollutants = new Set();
    const daysInMonth = new Set();

    data.forEach(item => {
      const { plantName, pollutantCode, hrMax, hrAvgTime } = item;
      const date = new Date(hrAvgTime);
      const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}`;

      uniquePlantNames.add(plantName);
      uniquePollutants.add(pollutantCode);
      daysInMonth.add(formattedDate);

      if (!plantPollutantMap[plantName]) {
        plantPollutantMap[plantName] = {};
      }

      if (!plantPollutantMap[plantName][pollutantCode]) {
        plantPollutantMap[plantName][pollutantCode] = { sum: 0, count: 0 };
      }

      plantPollutantMap[plantName][pollutantCode].sum += hrMax;
      plantPollutantMap[plantName][pollutantCode].count += 1;

      if (!dailyMaxData[formattedDate]) {
        dailyMaxData[formattedDate] = {};
      }

      if (!dailyMaxData[formattedDate][plantName]) {
        dailyMaxData[formattedDate][plantName] = {};
      }

      if (!dailyMaxData[formattedDate][plantName][pollutantCode] || hrMax > dailyMaxData[formattedDate][plantName][pollutantCode]) {
        dailyMaxData[formattedDate][plantName][pollutantCode] = hrMax;
      }
    });

    // Store dailyMaxData in state
    setDailyMaxData(dailyMaxData);

    // Prepare average data for bar chart
    const newAverageChartData = [];
    const averageChartLabels = Array.from(uniquePlantNames);

    averageChartLabels.forEach((plantName) => {
      Object.keys(plantPollutantMap[plantName]).forEach((pollutantCode) => {
        const avgHrMax = plantPollutantMap[plantName][pollutantCode].sum / plantPollutantMap[plantName][pollutantCode].count;

        let dataset = newAverageChartData.find(dataset => dataset.label === pollutantCode);
        if (!dataset) {
          dataset = {
            label: formatPollutantLabel(pollutantCode),
            backgroundColor: colors[newAverageChartData.length % colors.length],
            borderColor: colors[newAverageChartData.length % colors.length],
            borderWidth: 1,
            hoverBackgroundColor: colors[newAverageChartData.length % colors.length],
            hoverBorderColor: colors[newAverageChartData.length % colors.length],
            data: new Array(averageChartLabels.length).fill(0),
            type: 'bar' 
          };
          newAverageChartData.push(dataset);
        }

        const plantIndex = averageChartLabels.indexOf(plantName);
        dataset.data[plantIndex] = avgHrMax;
      });
    });

    // Prepare data for line chart
    const newLineChartData = [];
    const lineChartLabels = Array.from(daysInMonth);

    lineChartLabels.forEach((formattedDate) => {
      Object.keys(dailyMaxData[formattedDate]).forEach(plantName => {
        Object.keys(dailyMaxData[formattedDate][plantName]).forEach((pollutantCode) => {
          // Find dataset using the original pollutantCode (before formatting)
          let dataset = newLineChartData.find(dataset => dataset.label === formatPollutantLabel(pollutantCode));
    
          if (!dataset) {
            // Only create a new dataset if it doesn't already exist
            dataset = {
              label: formatPollutantLabel(pollutantCode),  // Apply formatted label for display
              data: new Array(lineChartLabels.length).fill(null),
              fill: false,
              backgroundColor: colorstwo[newLineChartData.length % colorstwo.length],  // Use a different color for each dataset
              borderColor: colorstwo[newLineChartData.length % colorstwo.length],
              type: 'line'
            };
            newLineChartData.push(dataset);
          }
    
          const dayIndex = lineChartLabels.indexOf(formattedDate);
          dataset.data[dayIndex] = dailyMaxData[formattedDate][plantName][pollutantCode];
        });
      });
    });
    
    

    setCombinedChartLabels(lineChartLabels);
    setAverageChartData(newAverageChartData);
    setLineChartData(newLineChartData);
    setPlantNames(Array.from(uniquePlantNames));
    setPollutants(Array.from(uniquePollutants));
  };

  const resetDashboardData = () => {
    setAverageChartData([]);
    setLineChartData([]);
    setCombinedChartLabels([]);
    setDailyMaxData({}); // Reset dailyMaxData as well
  };

  const handlePlantChange = (e) => {
    setSelectedPlant(e.target.value);
  };

  const handlePollutantChange = (e) => {
    setSelectedPollutant(e.target.value);
  };

  const filteredAverageChartData = useMemo(() => {
    if (!selectedPlant && !selectedPollutant) return averageChartData;

    return averageChartData
      .filter(dataset => selectedPollutant ? dataset.label === selectedPollutant : true)
      .map(dataset => ({
        ...dataset,
        data: selectedPlant ? dataset.data.filter((_, index) => plantNames[index] === selectedPlant) : dataset.data
      }));
  }, [averageChartData, selectedPlant, selectedPollutant, plantNames]);

  const filteredLineChartData = useMemo(() => {
    if (!selectedPlant && !selectedPollutant) return lineChartData;

    return lineChartData
      .filter(dataset => selectedPollutant ? dataset.label === selectedPollutant : true)
      .map(dataset => {
        const newData = [...dataset.data];
        combinedChartLabels.forEach((date, index) => {
          if (selectedPlant) {
            if (!dailyMaxData[date] || !dailyMaxData[date][selectedPlant] || dailyMaxData[date][selectedPlant][dataset.label] !== newData[index]) {
              newData[index] = null;
            }
          }
        });
        return {
          ...dataset,
          data: newData
        };
      });
  }, [lineChartData, selectedPlant, selectedPollutant, combinedChartLabels, dailyMaxData]);

  const combinedChartOptions = useMemo(() => ({
    legend: {
      display: true,
      labels: {
        fontColor: getComputedStyle(document.documentElement).getPropertyValue('--theme-text-color')
      }
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          fontColor: getComputedStyle(document.documentElement).getPropertyValue('--theme-text-color'),
          display: true,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0
        }
      },
      y: {
        grid: { display: false },
        ticks: {
          fontColor: getComputedStyle(document.documentElement).getPropertyValue('--theme-text-color')
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: { mode: 'index', intersect: false },
    hover: { mode: 'nearest', intersect: true },
    plugins: { datalabels: { display: false } },
  }), []);

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-sm-4">
          <label htmlFor="plantSelect">Select Plant:</label>
          <select id="plantSelect" value={selectedPlant} onChange={handlePlantChange} className="form-control">
            <option value="">All Plants</option>
            {plantNames.map((plantName, index) => (
              <option key={index} value={plantName}>{plantName}</option>
            ))}
          </select>
        </div>
        <div className="col-sm-4">
          <label htmlFor="pollutantSelect">Select Pollutant:</label>
          <select id="pollutantSelect" value={selectedPollutant} onChange={handlePollutantChange} className="form-control">
            <option value="">All Pollutants</option>
            {pollutants.map((pollutant, index) => (
              <option key={index} value={pollutant}>{formatPollutantLabel(pollutant)}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12" style={{ height: '400px' }}>
          <h5>Average Monthly Data</h5>
          {filteredAverageChartData.length > 0 ? (
            <Bar data={{ labels: plantNames, datasets: filteredAverageChartData }} options={combinedChartOptions} />
          ) : (
            <div className="alert alert-success">
              Data Loading...
            </div>
          )}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12" style={{ height: '400px' }}>
          <h5>Daily Max Trend</h5>
          {filteredLineChartData.length > 0 ? (
            <Line data={{ labels: combinedChartLabels, datasets: filteredLineChartData }} options={combinedChartOptions} />
          ) : (
            <div className="alert alert-success">
              Data Loading...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AmtDashboard;
