import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddPipeFrictionFactor from './AddPipeFrictionFactor'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function PipeFrictionFactorList() {
    const [pipeFrictionFactorData, setPipeFrictionFactorData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedfittingId, setSelectedfittingId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchPipeFrictionFactorData();
    }, []);

    const fetchPipeFrictionFactorData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/pipe/pipe-friction-factor`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPipeFrictionFactorData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Device data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    // const handleEdit = (id) => {
    //     navigate(`/admin/configurations/pipe/add-pipe-fitting?pipeFittingId=${id}`);
    // };

    // const handleDelete = async (fittingId) => {
    //     const confirmDelete = window.confirm(`Are you sure you want to delete Ghg Factor: ${fittingId}?`);
    //     if (confirmDelete) {
    //         try {
    //             const response = await fetch(`${API_BASE_URL}/pipe/pipe-fitting/delete/${fittingId}`, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 credentials: 'include'
    //             });

    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }

    //             toast.success('Successfully removed!', {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });

    //             fetchPipeFrictionFactorData();
    //         } catch (error) {
    //             console.error('Failed to delete header:', error);
    //         }
    //     }
    // };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = pipeFrictionFactorData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Pipe Fitting ID': item.pipeFittingId,
            'Min Pipe Diameter In': formatWithIndianThousandsSeparatorForExportData(item.minPipeDiameterIn ? item.minPipeDiameterIn.toFixed(2) : item.minPipeDiameterIn),
            'Max Pipe Diameter In': formatWithIndianThousandsSeparatorForExportData(item.maxPipeDiameterIn ? item.maxPipeDiameterIn.toFixed(2) : item.maxPipeDiameterIn),
            'Friction Factor': formatWithIndianThousandsSeparatorForExportData(item.frictionFactor ? item.frictionFactor.toFixed(4) : item.frictionFactor),
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, pipeFrictionFactorData]);

    const columns = [
        { name: 'Pipe Fitting ID', selector: row => row.pipeFittingId, sortable: true, resizable: true, pinned: 'left' },
        {
            name: 'Min Pipe Diameter In',
            selector: row => row.minPipeDiameterIn,
            cell: row => formatWithIndianThousandsSeparator(row.minPipeDiameterIn ? row.minPipeDiameterIn.toFixed(2) : row.minPipeDiameterIn),
            sortable: true,
            resizable: true
        },
        {
            name: 'Max Pipe Diameter In',
            selector: row => row.maxPipeDiameterIn,
            cell: row => formatWithIndianThousandsSeparator(row.maxPipeDiameterIn ? row.maxPipeDiameterIn.toFixed(2) : row.maxPipeDiameterIn),
            sortable: true,
            resizable: true
        },
        {
            name: 'Friction Factor',
            selector: row => row.frictionFactor,
            cell: row => formatWithIndianThousandsSeparator(row.frictionFactor ? row.frictionFactor.toFixed(4) : row.frictionFactor),
            sortable: true,
            resizable: true
        },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
        // {
        //     name: 'Edit',
        //     cell: row => (
        //         <FontAwesomeIcon
        //             icon={faPen}
        //             onClick={() => handleEdit(row.fittingId)}
        //             style={{ cursor: 'pointer' }}
        //         />
        //     )
        // },
        // {
        //     name: 'Delete',
        //     cell: row => (
        //         <FontAwesomeIcon
        //             icon={faTrash}
        //             onClick={() => handleDelete(row.fittingId)}
        //             style={{ cursor: 'pointer', color: 'red' }}
        //         />
        //     )
        // },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Pipe_Friction_Factor_${new Date().toLocaleDateString()}`;

    const title = "Pipe Friction Factors Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Pipe Friction Factors</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Pipes</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Pipe Fitting</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/pipe/add-pipe-friction-factor" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}



