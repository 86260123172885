import React, { useState, useEffect, useRef } from 'react';
import DataCorrectionService from '../services/DataCorrectionService';
import DataTable from 'react-data-table-component';
import { Button, Modal, Card } from 'react-bootstrap';
import EditAmmnetValidation from './EditAmmnetValidation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExportComponent from '../../../../common/ExportComponent';
import AmmnetValidationListExportComponent from '../../../../common/exports/AmmnetValidationListExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';
import { format } from 'date-fns';
import formatDate from '../../../../common/formatDate ';

const AmmnetValidationList = () => {
  const [ammnetValidationItems, setAmmnetValidationItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const datepickerRef = useRef();
  const [exportFilteredData, setExportFilteredData] = useState([]);

  const fetchAmmnetValidationData = async (date = '') => {
    setIsLoading(true);
    try {
      const response = await DataCorrectionService.getAmmnetValidation(date);
      setAmmnetValidationItems(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Failed to fetch Ammnet validation data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      fetchAmmnetValidationData(formattedDate);
    }
  }, [selectedDate]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = ammnetValidationItems.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered.push({
        txDateTime: '',
        deviceID: '',
        facilityID: '',
        plantID: '',
        neI_POLLUTANT_CODE: '',
        uomID: '',
        emission: '',
        reasonID: '',
        adjEmission: '',
        adjReasonID: '',
        engineerID: '',
        commentDate: '',
        userComments: ''
      });
    }

    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Tx Date': item.txDateTime ? formatDate(item.txDateTime) : '',
      'Source ID': item.deviceID,
      'Facility ID': item.facilityID,
      'Plant ID': item.plantID,
      'Pollutant': item.neI_POLLUTANT_CODE,
      'UoM': item.uomID,
      'Emission': formatWithIndianThousandsSeparatorForExportData(item.emission ? parseInt(item.emission).toFixed(0) : item.emission),
      'Reason': item.reasonID,
      'Adj Emission': formatWithIndianThousandsSeparatorForExportData(item.adjEmission ? parseInt(item.adjEmission).toFixed(0) : item.adjEmission),
      'Adj Reason': item.adjReasonID,
      'Engineer ID': item.engineerID,
      'Comment Date': item.commentDate ? new Date(item.commentDate).toLocaleString() : '',
      'User Comments': item.userComments
    }));
    setExportFilteredData(exportData);

  }, [search, ammnetValidationItems]);


  const handleEdit = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete item: ${id}?`);
    if (confirmDelete) {
      try {
        await DataCorrectionService.deleteManualValvesItem(id);
        const formattedDate = selectedDate ? `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}` : '';
        fetchAmmnetValidationData(formattedDate);
      } catch (error) {
        console.error('Failed to delete item:', error);
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const columns = [
    { name: 'Tx Date', selector: row => row.txDateTime ? new Date(row.txDateTime).toLocaleString() : '', sortable: true, resizable: true,
      cell: row => row.txDateTime ? formatDate(row.txDateTime) : ''
     },
    { name: 'Source ID', selector: row => row.deviceID, sortable: true, resizable: true },
    { name: 'Facility ID', selector: row => row.facilityID, sortable: true, resizable: true },
    { name: 'Plant ID', selector: row => row.plantID, sortable: true, resizable: true },
    { name: 'Pollutant', selector: row => row.neI_POLLUTANT_CODE, sortable: true, resizable: true },
    { name: 'UoM', selector: row => row.uomID, sortable: true, resizable: true },
    { name: 'Emission', 
      selector: row => row.emission != null ? row.emission : '',
      cell: row => formatWithIndianThousandsSeparator(row.emission ? parseInt(row.emission).toFixed(0) : row.emission),
      sortable: true, 
      resizable: true 
    },
    { name: 'Reason', selector: row => row.reasonID, sortable: true, resizable: true },
    { name: 'Adj Emission', 
      selector: row => row.adjEmission != null ? row.adjEmission : '',
      cell: row => formatWithIndianThousandsSeparator(row.adjEmission ? parseInt(row.adjEmission).toFixed(0) : row.adjEmission), 
      sortable: true, 
      resizable: true 
    },
    { name: 'Adj Reason', selector: row => row.adjReasonID, sortable: true, resizable: true },
    { name: 'Engineer ID', selector: row => row.engineerID, sortable: true, resizable: true },
    { name: 'Comment Date', selector: row => row.commentDate ? new Date(row.commentDate).toLocaleString() : '', sortable: true, resizable: true },
    { name: 'User Comments', selector: row => row.userComments, sortable: true, resizable: true },
    {
      name: 'Edit',
      width: '75px',
      cell: row => row.txDateTime ? (
        <FontAwesomeIcon
          icon={faPencilAlt}
          onClick={() => handleEdit(row)}
          style={{ cursor: 'pointer', color: 'blue' }}
        />
      ) : null,
    },
    // {
    //   name: 'Delete',
    //   cell: row => (
    //     <FontAwesomeIcon
    //       icon={faTrash}
    //       onClick={() => handleDelete(row.txDateTime)}
    //       style={{ cursor: 'pointer', color: 'red' }}
    //     />
    //   )
    // },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Amment_Validation_${new Date().toLocaleDateString()}`;

  const headerData = [
    { label: 'Select Date Range', value: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

const title = "AMMNET Validations";

  return (
    <div className="main-content">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className='breadcrumb mt-3'>
            <h1>AMMNET Validations</h1>
            <ul>
              <li>Data Corrections</li>
              <li>AMT</li>
            </ul>
            <div className="separator-breadcrumb border-top" />
          </div>
          {/* <div className="separator-breadcrumb border-top" /> */}
          <div className='row'>
            <div className="col-sm-3">
              <label htmlFor="dateRange" className="control-label">Select Date Range</label>
              <div className={'datepicker-parent'}>
                <DatePicker
                  ref={datepickerRef}
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select a date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Exceedances</h4> */}
                            <div className='row'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <AmmnetValidationListExportComponent data={exportFilteredData} columns={columns} headerData={headerData} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    customStyles={customStyles}
                                    pagination
                                    highlightOnHover
                                    striped
                                  />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Ammnet Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAmmnetValidation data={selectedData} onClose={() => setShowModal(false)} onSave={() => {
            const formattedDate = selectedDate ? `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}` : '';
            fetchAmmnetValidationData(formattedDate);
          }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AmmnetValidationList;
