import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddEmsDevice from './AddEmsDevice'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function EmsDeviceList() {
    const [emsDeviceData, setEmsDeviceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedemsDeviceId, setSelectedemsDeviceId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchEmsDeviceData();
    }, []);

    const fetchEmsDeviceData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/ems-device`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmsDeviceData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Source type data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/ems-device-master/add-ems-device/${id}`);
    };

    const handleDelete = async (emsDeviceId) => {
        // const confirmDelete = window.confirm(`Are you sure you want to delete reason: ${emsDeviceId}?`);
        // if (confirmDelete) {
        //     try {
        //         const response = await fetch(`${API_BASE_URL}/ems-master/delete/${emsDeviceId}`, {
        //             method: 'DELETE',
        //             credentials: 'include'
        //         });

        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }

        //         toast.success('Successfully removed!', {
        //             position: "top-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });

        //         fetchEmsDeviceData();
        //     } catch (error) {
        //         console.error('Failed to delete header:', error);
        //     }
        // }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = emsDeviceData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Source ID': item.deviceId,
            'Material State': item.materialState,
            'SCC': item.scc,
            'Method': item.method,
            'Primary': formatWithIndianThousandsSeparatorForExportData(item.primary),
            'Notes': item.notes,
            'Level1': item.level1,
            'Level2': item.level2,
            'Level3': item.level3,
            'Level4': item.level4,
            'Factor ID': item.factorId,
            'Pollutant': formatWithIndianThousandsSeparatorForExportData(item.pollutantId),
            'NEI Pollutant Code': item.neiPollutantCode,
            'Control ID': formatWithIndianThousandsSeparatorForExportData(item.controlId),
            'Measure': formatWithIndianThousandsSeparatorForExportData(item.ap42Section ? parseFloat(item.ap42Section).toFixed(0) : item.ap42Section),
            'Ref Desc': item.refDesc,
            'Measure': item.measure,
            'Unit': item.unit,
            'Calc Type': item.calcType,
            'Factor': formatWithIndianThousandsSeparatorForExportData(item.factor && !isNaN(item.factor) ? parseFloat(item.factor).toFixed(4) : '0.0000'),
            'Formula': item.formula,
            'Tooltip': item.tooltip,
            'Status': item.status
        }));
        setExportFilteredData(exportData);


    }, [search, emsDeviceData]);

    const columns = [
        { name: 'Source ID', selector: row => row.deviceId, sortable: true, resizable: true, pinned: 'left' },
        { name: 'Material State', selector: row => row.materialState, sortable: true, resizable: true },
        {
            name: 'SCC',
            selector: row => row.scc,
            cell: row => <div style={{ textAlign: 'right', width: '100%' }}>
                            {row.scc}
                         </div>,
            sortable: true,
            resizable: true
        },
        { name: 'Method', selector: row => row.method, sortable: true, resizable: true },
        {
            name: 'Primary',
            selector: row => row.primary,
            cell: row => formatWithIndianThousandsSeparator(row.primary),
            sortable: true,
            resizable: true
        },
        { name: 'Notes', selector: row => row.notes, sortable: true, resizable: true },
        { name: 'Level1', selector: row => row.level1, sortable: true, resizable: true },
        { name: 'Level2', selector: row => row.level2, sortable: true, resizable: true },
        { name: 'Level3', selector: row => row.level3, sortable: true, resizable: true },
        { name: 'Level4', selector: row => row.level4, sortable: true, resizable: true },
        {
            name: 'Factor ID',
            selector: row => row.factorId,
            cell: row => <div style={{ textAlign: 'right', width: '100%' }}>
                            {row.factorId}
                         </div>,
            sortable: true,
            resizable: true
        },
        {
            name: 'Pollutant',
            selector: row => row.pollutantId,
            cell: row => formatWithIndianThousandsSeparator(row.pollutantId),
            sortable: true,
            resizable: true
        },
        { name: 'NEI Pollutant Code ', selector: row => row.neiPollutantCode, sortable: true, resizable: true },
        {
            name: 'Control ID',
            selector: row => row.controlId,
            cell: row => formatWithIndianThousandsSeparator(row.controlId),
            sortable: true,
            resizable: true
        },
        {
            name: 'Measure',
            selector: row => row.ap42Section,
            cell: row => formatWithIndianThousandsSeparator(row.ap42Section ? parseFloat(row.ap42Section).toFixed(0) : row.ap42Section),
            sortable: true,
            resizable: true
        },
        { name: 'Ref Desc', selector: row => row.refDesc, sortable: true, resizable: true },
        { name: 'Measure', selector: row => row.measure, sortable: true, resizable: true },
        { name: 'Unit', selector: row => row.unit, sortable: true, resizable: true },
        { name: 'Calc Type', selector: row => row.calcType, sortable: true, resizable: true },
        { name: 'Factor', 
          selector: row => row.factor,
          cell: row => formatWithIndianThousandsSeparator(
            row.factor && !isNaN(row.factor) ? parseFloat(row.factor).toFixed(4) : '0.0000'
          ),
          sortable: true, 
          resizable: true 
        },
        { name: 'Formula', selector: row => row.formula, sortable: true, resizable: true },
        { name: 'Tooltip', selector: row => row.tooltip, sortable: true, resizable: true },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.id)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Ems_Source_${new Date().toLocaleDateString()}`;

    const title = "EMS Source Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>EMS Sources</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-EMS</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">EMS Source</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/ems-device-master/add-ems-device" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}


