import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import EntitySelector from '../../common/EntitySelector';
import ExcelFileGeneratorService from './services/ExcelFileGeneratorService';
import DataImportService from './services/DataImportService';

const FileUploadComponent = () => {
  const [entity, setEntity] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const entityParam = params.get('entity');
    if (entityParam) {
      setEntity(entityParam);
    }
  }, [location.search]);

  const onFileUpload = async (files) => {
    const file = files[0];
    setUploadFile(file);
    const parseResult = await parseExcelFile(file);
    if (parseResult) {
      DataImportService.setImportDataForEntity(entity, { entity, data: parseResult });
    }
    resetFileInputValue();
  };

  const invokeFileUpload = () => {
    document.getElementById('fileUploadInput').click();
  };

  const resetFileInputValue = () => {
    document.getElementById('fileUploadInput').value = null;
  };

  const resetFileUpload = () => {
    setUploadFile(null);
  };

  const goToMapColumn = () => {
    navigate(`/admin/data-import/finalize?entity=${entity}`);
  };

  const downloadExcelTemplate = () => {
    ExcelFileGeneratorService.generateAndDownloadImportExcelTemplate(entity);
  };

  const onEntitySelected = (selectedEntity) => {
    setEntity(selectedEntity);
    applyQueryParamsToCurrentUrl(selectedEntity);
  };

  const applyQueryParamsToCurrentUrl = (selectedEntity) => {
    navigate(`?entity=${selectedEntity}`, { replace: true });
  };

  const parseExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const parseResult = {
          sheetName,
          header: json[0],
          dataRows: json.slice(1),
        };
        resolve(parseResult);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <div className="container-fluid entity-selection">
      <div className='card mt-5'>
        <div className='card-body'>
        <div className="row">
        <div className="col-sm-12">
          <h4>Data Import Wizard</h4>
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex col-sm-12 justify-content-lg-between">
          <div style={{ width: '800px' }}>
            <EntitySelector selectedEntity={entity} onEntityChange={onEntitySelected} />
          </div>

          {entity && (
            <span
              className="badge badge-primary ml-2 font-weight-light p-1 text-center pt-3 mb-4"
              onClick={downloadExcelTemplate}
              style={{ width:'12%' }}
            >
              {/* <i className="i-Clouds-Weather"></i> */}
              <span>Download Template</span>
            </span>
          )}
        </div>
      </div>

      {entity && (
        <div className="container-fluid file-upload-group mt-3">
          <div className="row">
            <div className="col-sm-12 d-flex align-items-center">
              <h3>Data Import for {entity} Table</h3>
            </div>
          </div>
          <div className="file-upload-container text-center pre-upload" style={{ display: !uploadFile ? 'block' : 'none' }}>
            <div className="row mt-5">
              <div className="col-sm-12">
                <h1 className="lnr lnr-file-add text-primary"></h1>
              </div>
            </div>
            <div className='bulk-upload'>
            <div className="row my-2">
              <div className="col-sm-12">
                <h2 className="font-weight-lighter">Upload Excel File</h2>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-sm-12">
                <button type="button" className="btn btn-outline-warning btn-lg" onClick={invokeFileUpload}>Upload</button>
                <input
                  type="file"
                  id="fileUploadInput"
                  className="d-none"
                  onChange={(e) => onFileUpload(e.target.files)}
                />
              </div>
            </div>
            </div>
          </div>
          {uploadFile && (
            <div className="file-upload-container text-center">
              <div className="row mt-5">
                <div className="col-sm-12">
                  <h1 className="lnr lnr-checkmark-circle text-success"></h1>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-12">
                  <h2 className="font-weight-lighter">Upload complete!</h2>
                  <span className="file-info badge badge-primary">
                    {uploadFile.name}
                  </span>&nbsp; &nbsp;
                  <span className="file-info badge badge-danger" role="button" onClick={resetFileUpload}>
                    <span className="i-Close-Window"></span>
                  </span>
                </div>
              </div>
              <div className="row mt-3 mb-5">
                <div className="col-sm-12 d-flex flex-row justify-content-center">
                  <button
                    type="button"
                    className="btn btn-outline-info btn-lg d-flex align-items-center next-btn"
                    onClick={goToMapColumn}
                  >
                    <span className="mr-2">Next</span>
                    <span className="lnr lnr-arrow-right"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadComponent;
