import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DataImportService from './services/DataImportService';
import ExcelFileGeneratorService from './services/ExcelFileGeneratorService';
import { ImportStatus, ImportStatusNames } from '../../constants/ImportStatus';

const DataImportComponent = () => {
  const [entity, setEntity] = useState(null);
  const [importDataset, setImportDataset] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [importStatus, setImportStatus] = useState(ImportStatus.Review);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [hideImportButton, setHideImportButton] = useState(false);
  const [importFinish, setImportFinish] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const entityParam = params.get('entity');
    if (entityParam) {
      setEntity(entityParam);
      const importData = DataImportService.getImportDataForEntity(entityParam);
      if (importData) {
        setImportDataset(importData);
        buildGridOptions(importData);
        createAndApplyGridData(importData);
      }
    }
  }, [location.search]);

  const buildGridOptions = (importData) => {
    const entityColumnMap = ExcelFileGeneratorService.getEntityColumnMapModelForEntity(importData.entity);
    if (entityColumnMap) {
      const columnDefinitions = importData.data.header.map((header) => ({
        field: entityColumnMap.columnPropertyMap[header],
        headerName: header,
        sortable: true,
        resizable: true,
      }));
      setColumnDefs(columnDefinitions);
    }
  };

  const createAndApplyGridData = (importData) => {
    const entityColumnMap = ExcelFileGeneratorService.getEntityColumnMapModelForEntity(importData.entity);
    const rowData = importData.data.dataRows.map((row) => {
      const rowItem = {};
      row.forEach((cell, index) => {
        rowItem[entityColumnMap.columnPropertyMap[importData.data.header[index]]] = cell;
      });
      return rowItem;
    });
    setGridData(rowData);
  };

  const cancelImport = () => {
    DataImportService.resetImportDataForEntity(entity);
    navigate(`/admin/data-import/file-upload?entity=${entity}`);
  };

  const importData = () => {
    setHideImportButton(true);
    DataImportService.uploadDataAndTryImport(entity, gridData).then(onImportSuccess).catch(onImportFailure);
  };

  const downloadFailedRows = () => {
    if (importResult && !importResult.isSuccess && importResult.failedResults && importResult.failedResults.length > 0) {
      const results = importResult.failedResults.map((item) => {
        const sheetRow = {};
        const entityColumnMap = ExcelFileGeneratorService.getEntityColumnMapModelForEntity(entity);
        for (let key in item) {
          if (!entityColumnMap.propertyColumnMap[key]) continue;
          sheetRow[entityColumnMap.propertyColumnMap[key]] = item[key];
        }
        return sheetRow;
      });
      ExcelFileGeneratorService.generateAndDownloadImportExcelTemplateWithData(entity, results);
    }
  };

  const onImportSuccess = (response) => {
    setImportResult(response);
    setHideImportButton(true);
    setImportStatus(response.isSuccess ? ImportStatus.Complete : ImportStatus.Partial);
    setImportFinish(true);
  };

  const onImportFailure = (response) => {
    setImportResult(response);
    setImportStatus(response.failedResults.length === importDataset.data.dataRows.length ? ImportStatus.Fail : ImportStatus.Partial);
    setHideImportButton(false);
  };

  return (
    <div className="container-fluid">
      <div className='card mt-3'>
      <div className='card-header'><h3>Data import for {entity}</h3></div>
        <div className='card-body'>
        <div className="row">
        <div className="col-sm-12">
          {importDataset && (
            <p>
              Import status:
              <span className={`badge mx-2 ${importStatus === ImportStatus.Review ? 'badge-primary' : importStatus === ImportStatus.Complete ? 'badge-success' : importStatus === ImportStatus.Partial ? 'badge-warning' : 'badge-danger'}`}>
                {ImportStatusNames[importStatus]}
              </span>
              {importStatus === ImportStatus.Fail && (
                <>
                  Import failed, click here to <span role="button" className="badge badge-info" onClick={downloadFailedRows}>download</span> the failed rows.
                </>
              )}
              {importStatus === ImportStatus.Partial && (
                <>
                  Import partially succeeded, click here to <span role="button" className="badge badge-info" onClick={downloadFailedRows}>download</span> the failed rows.
                </>
              )}
            </p>
          )}
        </div>
      </div>
      {!importDataset && !importFinish && (
        <div className="row mt-3">
          <div className="col-sm-8">
            <div className="alert alert-info">
              Cannot find data to import, please go back & <span className="badge badge-primary" role="button" onClick={() => navigate(`/admin/data-import/file-upload?entity=${entity}`)}>upload</span> a file to perform import.
            </div>
          </div>
        </div>
      )}
      {importFinish && (
        <div className="row">
          <div className="col-sm-8">
            <div className="alert alert-success">
              Import finished! Go to configuration {'>'} {entity} to view results. To do more imports go to <span className="badge badge-primary" role="button" onClick={() => navigate(`/admin/data-import/file-upload?entity=${entity}`)}>upload</span>
            </div>
          </div>
        </div>
      )}
      {importDataset && (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={gridData}
                  columnDefs={columnDefs}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 text-right">
              {!hideImportButton && (
                <button type="button" className="btn btn-primary mr-3" onClick={importData}>Import</button>
              )}
              <button type="button" className="btn btn-secondary" onClick={cancelImport}>Cancel</button>
            </div>
          </div>
        </>
      )}
        </div>
      </div>
      
    </div>
  );
};

export default DataImportComponent;
