// "ConnectionStrings": {
//     "IPEMS": "Data Source=gems-2024.cmme6vnorhr4.us-west-2.rds.amazonaws.com,1433;Initial Catalog=IPEMS;User ID=gems;password=gems2022_Admin"
//   },
  // "ConnectionStrings": {
  //   "IPEMS": "Data Source=74.249.50.39,1433;Initial Catalog=IPEMS;User ID=sa2;password=greenVue@cloud"
  // },

  // "ConnectionStrings": {
  //   "IPEMS": "Data Source=rensol-greenvue.database.windows.net,1433;Initial Catalog=IPEMS;User ID=greenvuesqladmin;password=Rensol7admin"
  // },

  // EXEC sp_helptext 'GetMaxHourlyAMTAvg'; stored procedure query
  // Select AVG(AdjEmission) from dbo.IP_DailyAMTTx where txDateTime between '2023/12/01'and '2023/12/31' and   plantID = 'IND1_KB2' and NEI_POLLUTANT_CODE = 'NOX'; calculating avg of amt daily

import FmsDashboard from './components/views/features/fms-dashboard/dashboards/FmsDashboard'
import EmsDashboard from './components/views/features/ems-dashboard/dashboard/EmsDashboard';
import GhgDashboard from './components/views/features/ghg-dashboard/dashboard/GhgDashboard';
import AmtDashboard from './components/views/features/amt-dashboard/dashboard/AmtDashboard';
import Dashboard from "./components/views/dashboards/fms/Dashboard";
import HeaderWiseFlaring from "./components/views/flaring/header-wise-flaring/HeaderWiseFlaring";
import PlantFlaringAnalysis from "./components/views/flaring/plant-flaring-analysis/PlantFlaringAnalysis";
import PlantWiseFlaring from "./components/views/flaring/PlantWiseFlaring";
import DailyFlaring from "./components/views/flaring/DailyFlaring";
import DeviceFlaring from './components/views/flaring/device-flaring/DeviceFlaring';
import FlaringReasons from "./components/views/flaring/FlaringReasons";
import FacilityWiseFlaring from "./components/views/flaring/FacilityWiseFlaring";
import UnAccountedFlaring from "./components/views/flaring/UnAccountedFlaring";
import PlanVsActual from "./components/views/flaring/PlanVsActual";
import FlaringAudit from "./components/views/flaring/FlaringAudit";
import FlaringKPIReport from "./components/views/flaring/flaring-kpi-report/FlaringKPIReport";

// emissions Routes
import FacilityEmissions from "./components/views/emission/FacilityEmissions";
import PlantEmissions from "./components/views/emission/PlantEmissions";
import SourceEmissions from "./components/views/emission/SourceEmissions";
import HourlyEmissions from "./components/views/emission/HourlyEmissions";
import EmissionReasons from "./components/views/emission/EmissionReasons";
import StackEmissions from "./components/views/emission/StackEmissions";
import PlanVsActualEmission from "./components/views/emission/PlanVsActualEmission";

// greenhouse gases routes 
import GreenHouseFacilityEmissions from "./components/views/greenhousegases/GreenHouseFacilityEmissions";
import GreenHousePlantEmissions from "./components/views/greenhousegases/GreenHousePlantEmissions";
import GreenHouseSourceEmissions from "./components/views/greenhousegases/GreenHouseSourceEmissions";
import GreenHouseHourlyEmissions from "./components/views/greenhousegases/GreenHouseHourlyEmissions";
import GreenHouseStackEmissions from "./components/views/greenhousegases/GreenHouseStackEmissions";
import GreenHouseEmissionReasons from "./components/views/greenhousegases/GreenHouseEmissionReasons";
import GreenHousePlanVsActualEmissions from "./components/views/greenhousegases/GreenHousePlanVsActualEmissions";
import GreenHouseEmissionByScope from "./components/views/greenhousegases/GreenHouseEmissionByScope";
import GreenHouseCompanyWideGhg from "./components/views/greenhousegases/GreenHouseCompanyWideGhg ";
import GreenHouseGHGInventory from "./components/views/greenhousegases/GreenHouseGHGInventory";
import GreenHouseInventoryByScope from "./components/views/greenhousegases/GreenHouseInventoryByScope";
import GreenHouseGHGUncertainties from "./components/views/greenhousegases/GreenHouseGHGUncertainties";

// amment routes

import AmmentStationEmissions from "./components/views/ammnet/AmmentStationEmissions";
import AmmentSourceEmissions from "./components/views/ammnet/AmmentSourceEmissions";
import AmmentPeriodicEmission from "./components/views/ammnet/AmmentPeriodicEmission";
import AmmentRegionEmissions from "./components/views/ammnet/AmmentRegionEmissions";
import AmmentEmissionReasons from "./components/views/ammnet/AmmentEmissionReasons";
import AmmentExceedances from "./components/views/ammnet/AmmentExceedances";
import AmmentViolations from "./components/views/ammnet/AmmentViolations";

// health check routes starts here 
// health check errors routes start here 
import FlareTxErrors from './components/views/healthcheck/errors/FlareTxErrors';
import EmissionsTxErrors from './components/views/healthcheck/errors/EmissionsTxErrors';
import GhgTxErrors from './components/views/healthcheck/errors/GhgTxErrors';
import AmmnetTxErrors from './components/views/healthcheck/errors/AmmnetTxErrors';

import HealthCheckFlaringErrors from "./components/views/healthcheck/HealthCheckFlaringErrors";
import HealthCheckEmissionErrors from "./components/views/healthcheck/HealthCheckEmissionErrors";
import HealthCheckGHGErrors from "./components/views/healthcheck/HealthCheckGHGErrors";
import HealthCheckAmmnetErrors from "./components/views/healthcheck/HealthCheckAmmnetErrors";
import FMSHealthCheck from "./components/views/healthcheck/FMSHealthCheck";
import EmsHealthCheck from './components/views/healthcheck/EmsHealthCheck';

// transcations routes start here 
// data correction routes start here 
import FlowValidationList from './components/views/healthcheck/data-correction/flow-validation/FlowValidationList';
import EmissionValidationList from './components/views/healthcheck/data-correction/emission-validation/EmissionValidationList';
import GhgValidationList from './components/views/healthcheck/data-correction/ghg-validation/GhgValidationList';
import AmmnetValidationList from './components/views/healthcheck/data-correction/ammnet-validation/AmmnetValidationList';
// import TransactionManualValves from "./components/views/transactions/TransactionManualValves";

//operational routes start here 
import ShutdownScheduleList from './components/views/operational-data/shutdown-schedule/ShutdownScheduleList';
import ManualValvesList from './components/views/operational-data/manual-valves/ManualValvesList';

// Configration Routes start here

// orgnization routes starts here 
//area routes starts here
import AreaList from "./components/views/configurations/organisation/area/AreaList";
import AddArea from "./components/views/configurations/organisation/area/AddAreaOld";

//facility routes starts here 
import FacilityList from "./components/views/configurations/organisation/facility/FacilityList";
import AddFacility from "./components/views/configurations/organisation/facility/AddFacility";

//plants routes starts here
import PlantList from "./components/views/configurations/organisation/plant/PlantList"; 

// facility equity routes starts here 
import FacilityEquity from "./components/views/configurations/organisation/facility-equity/FacilityEquity";

// flare-master routes starts here 
//header routes starts here

import HeaderList from "./components/views/configurations/flare-master/header/HeaderList";
import AddHeader from "./components/views/configurations/flare-master/header/AddHeader";

// reasons routes starts here 
import ReasonList from "./components/views/configurations/flare-master/reason/ReasonList";
import AddReason from "./components/views/configurations/flare-master/reason/AddReason";

// facility feeds routes starts here 
import FacilityFeedsList from "./components/views/configurations/flare-master/facility-feeds/FacilityFeedsList";
import AddFacilityFeed from "./components/views/configurations/flare-master/facility-feeds/AddFacilityFeed";

//device-master routes starts here
//device-clasifications routes start here 
import DeviceClassificationList from "./components/views/configurations/device-master/device-classification/DeviceClassificationList";
import AddDeviceClassification from "./components/views/configurations/device-master/device-classification/AddDeviceClassification";

//device-type routes start here 
import DeviceTypesList from "./components/views/configurations/device-master/device-types/DeviceTypesList";
import AddDeviceType from "./components/views/configurations/device-master/device-types/AddDeviceType";

//devices routes start here

import DeviceList from "./components/views/configurations/device-master/device/DeviceList";
import AddDevice from "./components/views/configurations/device-master/device/AddDevice";

//ems-devices routes starts here
//ems-factor routes starts here
import EmsFactorList from "./components/views/configurations/ems-device-master/ems-factors/EmsFactorList";

//ems-method routes starts here
import EmsMethodList from "./components/views/configurations/ems-device-master/ems-methods/EmsMethodList";
import AddEmsMethod from "./components/views/configurations/ems-device-master/ems-methods/AddEmsMethod";

//ems-device-type routes starts here
import EmsDeviceTypeList from "./components/views/configurations/ems-device-master/ems-device-type/EmsDeviceTypeList";

//ems-device-type routes starts here
import EmsDeviceList from "./components/views/configurations/ems-device-master/ems-device/EmsDeviceList";
import AddEmsDevice from "./components/views/configurations/ems-device-master/ems-device/AddEmsDevice";

//ghg-devices routes starts here
//ghg-factor routes starts here
import GhgFactorList from "./components/views/configurations/ghg-master/ghg-factor/GhgFactorList";
import AddGhgFactor from "./components/views/configurations/ghg-master/ghg-factor/AddGhgFactor";

//ghg-method routes starts here
import GhgMethodList from "./components/views/configurations/ghg-master/ghg-method/GhgMethodList";
import AddGhgMethod from "./components/views/configurations/ghg-master/ghg-method/AddGhgMethod";

//ghg-device-type routes starts here
import GhgDeviceTypeList from "./components/views/configurations/ghg-master/ghg-device-type/GhgDeviceTypeList";

//gwp-potential routes starts here
import GwpPotentialList from "./components/views/configurations/ghg-master/gwp-potential/GwpPotentialList";

//ghg-device routes starts here
import GhgDeviceList from "./components/views/configurations/ghg-master/ghg-device/GhgDeviceList";
import AddGhgDevice from "./components/views/configurations/ghg-master/ghg-device/AddGhgDevice";

//amt-device routes starts here
import AmtDeviceList from "./components/views/configurations/amt-device-master/amt-device/AmtDeviceList";
import AddAmtDevice from "./components/views/configurations/amt-device-master/amt-device/AddAmtDevice";

//amt-exceedance routes starts here
import AmtExceedanceList from "./components/views/configurations/amt-device-master/exceedance-list/AmtExceedanceList";
import AddAmtExceedance from "./components/views/configurations/amt-device-master/exceedance-list/AddAmtExceedance";

// composition routes stsrts here 
//physical-properties routes starts here
import PhysicalPropertiesList from "./components/views/configurations/composition/physical-properties/PhysicalPropertiesList";
import AddPhysicalProperties from "./components/views/configurations/composition/physical-properties/AddPhysicalProperties";

//liquid-composition routes starts here
import LiquidCompositionList from "./components/views/configurations/composition/liquid-composition/LiquidCompositionList";
import AddLiquidComposition from "./components/views/configurations/composition/liquid-composition/AddLiquidComposition";

//pollutant-limits routes starts here
import PollutantLimitList from "./components/views/configurations/composition/pollutant-limits/PollutantLimitList";
import AddPollutantLimit from "./components/views/configurations/composition/pollutant-limits/AddPollutantLimit";

//equipment routes starts here 
import EquipmentList from "./components/views/configurations/equipment/equipment-data/EquipmentList";
import AddEquipment from "./components/views/configurations/equipment/equipment-data/AddEquipment";

//equipment clasification routes starts here 
import EquipmentClassificationList from "./components/views/configurations/equipment/equipment-classification/EquipmentClassificationList";
import AddEquipmentClassification from "./components/views/configurations/equipment/equipment-classification/AddEquipmentClassification";

//pipes routes starts here 
// pipe-fitting routes starts here 
import PipeFittingList from "./components/views/configurations/pipes/pipe-fitting/PipeFittingList";
import AddPipeFitting from "./components/views/configurations/pipes/pipe-fitting/AddPipeFitting";

// pipe-frication-factor routes starts here 
import PipeFrictionFactorList from "./components/views/configurations/pipes/pipe-friction-factor/PipeFrictionFactorList";
import AddPipeFrictionFactor from "./components/views/configurations/pipes/pipe-friction-factor/AddPipeFrictionFactor";

//flow coefficient routes starts here
import FlowCoefficientList from "./components/views/configurations/pipes/flow-coefficient/FlowCoefficientList";
import AddFlowCoefficient from "./components/views/configurations/pipes/flow-coefficient/AddFlowCoefficient";

// system-plan routes starts here 
// flaring-plan routes starts here 
import FlaringPlanList from "./components/views/configurations/system-plan/flaring-plan/FlaringPlanList"; 
import AddFlaringPlan from "./components/views/configurations/system-plan/flaring-plan/AddFlaringPlan";
// emission-plan routes starte here 
import EmissionPlanList from "./components/views/configurations/system-plan/emission-plan/EmissionPlanList";
import AddEmissionPlan from "./components/views/configurations/system-plan/emission-plan/AddEmissionPlan";

// company-master routes starts here
// tag-master routes start here 
import TagsMasterList from "./components/views/configurations/master/tags-master/TagsMasterList";
import AddTagMasterVOne from "./components/views/configurations/master/tags-master/AddTagMasterVOne";
import AddTagMasterVTwo from "./components/views/configurations/master/tags-master/AddTagMasterVTwo";

// code-master routes start here 
import CodeMasterList from "./components/views/configurations/master/code-master/CodeMasterList";
import AddCodeMaster from "./components/views/configurations/master/code-master/AddCodeMaster";

//uom routes start here
import UomList from "./components/views/configurations/master/uom/UomList";
import AddUom from "./components/views/configurations/master/uom/AddUom";

//uom-conversion routes start here 
import UomConversionList from "./components/views/configurations/master/uom-conversions/UomConversionList";
import AddUomConversion from "./components/views/configurations/master/uom-conversions/AddUomConversion";

// status routes start here 
import StatusList from "./components/views/configurations/master/status/StatusList";
import AddStatus from "./components/views/configurations/master/status/AddStatus";

// annual-prices routes start here 
import AnnualPriceList from "./components/views/configurations/master/annual-prices/AnnualPriceList";
import AddAnnualPrice from "./components/views/configurations/master/annual-prices/AddAnnualPrice";

//default setting routes start here 
import SystemDefaults from './components/views/configurations/default-settings/SystemDefaults';

//notification routes starts here 
//user-notification routes start here 
import UsersConfigurationList from "./components/views/configurations/master/notification/notification-Users-Configuration/UsersConfigurationList";
import AddUsersConfiguration from "./components/views/configurations/master/notification/notification-Users-Configuration/AddUsersConfiguration";

// subscribe-notification start here 
import SubscriberConfigurationList from "./components/views/configurations/master/notification/notification-Subscribers-Configuration/SubscriberConfigurationList";
import AddSubscribeConfiguration from "./components/views/configurations/master/notification/notification-Subscribers-Configuration/AddSubscribeConfiguration";

//rule-notification routes start here 
import RuleConfigurationList from "./components/views/configurations/master/notification/notification-Rule-Configuration/RuleConfigurationList";
import AddRuleConfiguration from "./components/views/configurations/master/notification/notification-Rule-Configuration/AddRuleConfiguration";

// upload file component routes start here
import FileUploadComponent from './components/views/ems-import/FileUploadComponent';
import DataImportComponent from './components/views/ems-import/DataImportComponent';

// projects routes starts here 
//project master routes start here 
import ListProjectMaster from './components/views/project/project-master/ListProjectMaster';
import AddProjectMaster from './components/views/project/project-master/AddProjectMaster';

// project transaction routes start here 
import ListProjectTransaction from './components/views/project/project-transaction/ListProjectTransaction';
import AddProjectTransaction from './components/views/project/project-transaction/AddProjectTransaction';

//project category routes start here 
import ListProjectCategory from './components/views/project/project-category/ListProjectCategory';
// project type routes starts here
import ListProjectType from './components/views/project/project-type/ListProjectType';
// project status routes start here 
import ListProjectStatus from './components/views/project/project-status/ListProjectStatus';

// help routes start here 
import HelpComponent from './components/views/help/HelpComponent';

// import Powerbi from './components/views/powerbi/Powerbi';

var routes = [
  
  // {
  //   path: "/powerbi",
  //   name: "Power bi",
  //   icon: "tim-icons icon-bell-55",
  //   component: <Powerbi />,
  //   layout: "/admin",
  // },
  {
    path: "/flaring",
    name: "Fms Dashboard",
    icon: "tim-icons icon-bell-55",
    component: <FmsDashboard />,
    layout: "/admin",
  },
  {
    path: "/emissions",
    name: "Ems Dashboard",
    icon: "tim-icons icon-bell-55",
    component: <EmsDashboard />,
    layout: "/admin",
  },
  {
    path: "/greenhouse",
    name: "Ghg Dashboard",
    icon: "tim-icons icon-bell-55",
    component: <GhgDashboard />,
    layout: "/admin",
  },
  {
    path: "/ammnet",
    name: "Amt Dashboard",
    icon: "tim-icons icon-bell-55",
    component: <AmtDashboard />,
    layout: "/admin",
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "tim-icons icon-bell-55",
  //   component: <Dashboard />,
  //   layout: "/admin",
  // },
  {
    path: "/reports/flaring-reports/header-wise-flaring",
    // name: "Header Wise Flaring",
    name: "Header  Flaring",
    icon: "tim-icons icon-bell-55",
    component: <HeaderWiseFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/daily-plant-wise-flaring",
    name: "Plant Wise Flaring",
    icon: "tim-icons icon-bell-55",
    component: <PlantWiseFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/daily-flaring",
    name: "Daily Flaring",
    icon: "tim-icons icon-bell-55",
    component: <DailyFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/gasflaring",
    name: "Plant Flaring Analysis",
    icon: "tim-icons icon-bell-55",
    component: <PlantFlaringAnalysis />,
    layout: "/admin",
  },

  {
    path: "/reports/flaring-reports/device-flaring",
    name: "Device Flaring",
    icon: "tim-icons icon-bell-55",
    component: <DeviceFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/reason-wise-flaring",
    name: "Flaring Reasons",
    icon: "tim-icons icon-bell-55",
    component: <FlaringReasons />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/facility-wise-flaring",
    name: "Facilitywise Flaring",
    icon: "tim-icons icon-bell-55",
    component: <FacilityWiseFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/un-accounted-flaring",
    name: "Un Accounted Flaring",
    icon: "tim-icons icon-bell-55",
    component: <UnAccountedFlaring />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/plan-vs-actual",
    name: "Plan Vs Actual",
    icon: "tim-icons icon-bell-55",
    component: <PlanVsActual />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/flaring-audit",
    name: "Flaring Audit",
    icon: "tim-icons icon-bell-55",
    component: <FlaringAudit />,
    layout: "/admin",
  },
  {
    path: "/reports/flaring-reports/flaring-kpi",
    name: "Flaring KPI Report",
    icon: "tim-icons icon-bell-55",
    component: <FlaringKPIReport />,
    layout: "/admin",
  },

  // flaring routes end here 

  // emissions Routes starts here 

  {
    path: "/reports/emission-reports/FacilityEmission",
    name: "Facility Emissions",
    icon: "tim-icons icon-bell-55",
    component: <FacilityEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/EmissionAnalysisByPlant",
    name: "Plant Emissions",
    icon: "tim-icons icon-bell-55",
    component: <PlantEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/EmissionAnalysisByHeader",
    name: "Source Emissions",
    icon: "tim-icons icon-bell-55",
    component: <SourceEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/EmissionAnalysisByHourly",
    name: "Hourly Emissions",
    icon: "tim-icons icon-bell-55",
    component: <HourlyEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/ReasonsEmission",
    name: "Emission Reasons",
    icon: "tim-icons icon-bell-55",
    component: <EmissionReasons />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/StacksEmission",
    name: "Stack Emissions",
    icon: "tim-icons icon-bell-55",
    component: <StackEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/emission-reports/PlanVsActualEmission",
    name: "Plan Vs Actual Emissions",
    icon: "tim-icons icon-bell-55",
    component: <PlanVsActualEmission />,
    layout: "/admin",
  },

  // emission routes ends here 

  //green house gases routes starts here 

  {
    path: "/reports/ghg-reports/facility-ghg-emissions",
    name: "GreenHouse Facility Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseFacilityEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/plant-ghg-emission",
    name: "GreenHouse Plant Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHousePlantEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/source-ghg-emission",
    name: "GreenHouse Source Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseSourceEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/hourly-ghg-emission",
    name: "GreenHouse Hourly Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseHourlyEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/stacks-ghg-emission",
    name: "GreenHouse Stack Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseStackEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/ghg-reasons-emission",
    name: "GreenHouse Stack Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseEmissionReasons />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/planvsactual-ghg-emission",
    name: "GreenHouse Plan Vs Actual Emissions",
    icon: "tim-icons icon-bell-55",
    component: <GreenHousePlanVsActualEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/scope-wise-ghg-emission",
    name: "GreenHouse Emission By Scope",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseEmissionByScope />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/emission-by-greenhouse-gas",
    name: "GreenHouse Companywide Ghg",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseCompanyWideGhg />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/ghg-inventory",
    name: "GreenHouse GHG Inventory",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseGHGInventory />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/ghg-inventory-scope",
    name: "GreenHouse Inventory By Scope",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseInventoryByScope />,
    layout: "/admin",
  },
  {
    path: "/reports/ghg-reports/uncertainity-report",
    name: "GreenHouse GHG Uncertainties",
    icon: "tim-icons icon-bell-55",
    component: <GreenHouseGHGUncertainties />,
    layout: "/admin",
  },

  // greengases routes end here 

  // amment Routes starts here 

  
  {
    path: "/reports/ammnet-reports/PlantEmission",
    name: "Amment Station Emissions",
    icon: "tim-icons icon-bell-55",
    component: <AmmentStationEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/SourceEmission",
    name: "Amment Source Emissions",
    icon: "tim-icons icon-bell-55",
    component: <AmmentSourceEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/PeriodicEmission",
    name: "Amment Periodic Emissions",
    icon: "tim-icons icon-bell-55",
    component: <AmmentPeriodicEmission />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/RegionEmission",
    name: "Amment Region Emissions",
    icon: "tim-icons icon-bell-55",
    component: <AmmentRegionEmissions />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/ReasonsEmission",
    name: "Ammment Emission Reasons",
    icon: "tim-icons icon-bell-55",
    component: <AmmentEmissionReasons />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/Exceedances",
    name: "Amment Exceedances",
    icon: "tim-icons icon-bell-55",
    component: <AmmentExceedances />,
    layout: "/admin",
  },
  {
    path: "/reports/ammnet-reports/Violations",
    name: "Amment Violations",
    icon: "tim-icons icon-bell-55",
    component: <AmmentViolations />,
    layout: "/admin",
  },

  // amment routes end here 

  // health check routes starts here 
  // health check errors routes start here
  {
    path: "/alerts/fmstxerrors",
    name: "Flare TX Errors",
    icon: "tim-icons icon-bell-55",
    component: <FlareTxErrors />,
    layout: "/admin",
  },
  {
    path: "/alerts/emstxerrors",
    name: "Emissions TX Errors",
    icon: "tim-icons icon-bell-55",
    component: <EmissionsTxErrors />,
    layout: "/admin",
  },
  {
    path: "/alerts/ghgtxerrors",
    name: "GHG TX Errors",
    icon: "tim-icons icon-bell-55",
    component: <GhgTxErrors />,
    layout: "/admin",
  },
  {
    path: "/alerts/ammnettxerrors",
    name: "Ammnet TX Errors",
    icon: "tim-icons icon-bell-55",
    component: <AmmnetTxErrors />,
    layout: "/admin",
  },
  {
    path: "alerts/fmshealthcheck",
    name: "FMSHealthCheck",
    icon: "tim-icons icon-bell-55",
    component: <FMSHealthCheck />,
    layout: "/admin",
  },
  {
    path: "alerts/emshealthcheck",
    name: "FMSHealthCheck",
    icon: "tim-icons icon-bell-55",
    component: <EmsHealthCheck />,
    layout: "/admin",
  },
  // health check routes end here

  // transcations routes start here 
  // data-correction routes start here 
  {
    path: "transactions/flow-validation-list",
    name: "Flow Validations",
    icon: "tim-icons icon-bell-55",
    component: <FlowValidationList />,
    layout: "/admin",
  },

  {
    path: "transactions/emission-validation-list",
    name: "Emission Validations",
    icon: "tim-icons icon-bell-55",
    component: <EmissionValidationList />,
    layout: "/admin",
  },
  {
    path: "transactions/ghg-validation-list",
    name: "GHG Validations",
    icon: "tim-icons icon-bell-55",
    component: <GhgValidationList />,
    layout: "/admin",
  },
  {
    path: "transactions/ammnet-validation-list",
    name: "AMMNET Validations",
    icon: "tim-icons icon-bell-55",
    component: <AmmnetValidationList />,
    layout: "/admin",
  },
  {
    path: "transactions/manual-valves-list",
    name: "Transaction ManualValves",
    icon: "tim-icons icon-bell-55",
    component: <ManualValvesList />,
    layout: "/admin",
  },

  // operational routes start here 
  {
    path: "transactions/shutdown-schedule-list",
    name: "Shutdown Schedule List",
    icon: "tim-icons icon-bell-55",
    component: <ShutdownScheduleList />,
    layout: "/admin",
  },


  // Configration Routes start here
  // orgnization routes starts here 
  //area routes starts here
  {
    path: "configurations/area",
    name: "Area List",
    icon: "tim-icons icon-bell-55",
    component: <AreaList />,
    layout: "/admin",
  },
  {
    path: "configurations/area/add-area",
    name: "Add Area",
    icon: "tim-icons icon-bell-55",
    component: <AddArea />,
    layout: "/admin",
  },

  // facility routes starts here
  {
    path: "configurations/facility",
    name: "Facility List",
    icon: "tim-icons icon-bell-55",
    component: <FacilityList />,
    layout: "/admin",
  },
  {
    path: "configurations/facility/add-facility",
    name: "Add Facility",
    icon: "tim-icons icon-bell-55",
    component: <AddFacility />,
    layout: "/admin",
  },

  //plants routes starts here
  {
    path: "configurations/plant",
    name: "Plant List",
    icon: "tim-icons icon-bell-55",
    component: <PlantList />,
    layout: "/admin",
  },
  
  // facility equity routes starts here 
  {
    path: "configurations/ghg-master/list-facility-equity",
    name: "Facility Equity List",
    icon: "tim-icons icon-bell-55",
    component: <FacilityEquity />,
    layout: "/admin",
  },

   // flare-master routes starts here 
  //header routes starts here
  {
    path: "configurations/ems-master/list-header",
    name: "Header List",
    icon: "tim-icons icon-bell-55",
    component: <HeaderList />,
    layout: "/admin",
  },
  {
    path: "configurations/ems-master/add-header",
    name: "Add Header",
    icon: "tim-icons icon-bell-55",
    component: <AddHeader />,
    layout: "/admin",
  },
  // reasons routes starts here 
  {
    path: "configurations/ems-master/list-reason",
    name: "Reason List",
    icon: "tim-icons icon-bell-55",
    component: <ReasonList />,
    layout: "/admin",
  },
  {
    path: "configurations/ems-master/add-reason",
    name: "Reason List",
    icon: "tim-icons icon-bell-55",
    component: <AddReason />,
    layout: "/admin",
  },

  {
    path: "configurations/ems-master/list-facility-feed",
    name: "Facility Feeds List",
    icon: "tim-icons icon-bell-55",
    component: <FacilityFeedsList />,
    layout: "/admin",
  },
  {
    path: "configurations/ems-master/add-facility-feed",
    name: "Add Facility Feeds",
    icon: "tim-icons icon-bell-55",
    component: <AddFacilityFeed />,
    layout: "/admin",
  },

  //device-master routes starts here
  //device-clasifications routes start here 
  {
    path: "configurations/device/device-classification-list",
    name: "Device Classification List",
    icon: "tim-icons icon-bell-55",
    component: <DeviceClassificationList />,
    layout: "/admin",
  },
  {
    path: "configurations/device/add-device-classification",
    name: "Add Device Classification",
    icon: "tim-icons icon-bell-55",
    component: <AddDeviceClassification />,
    layout: "/admin",
  },
  //device-type routes start here 
  {
    path: "configurations/device/list-device-type",
    name: "Device Types List",
    icon: "tim-icons icon-bell-55",
    component: <DeviceTypesList />,
    layout: "/admin",
  },
  {
    path: "configurations/device/add-device-type/:deviceTypeId?",
    name: "Device Types List",
    icon: "tim-icons icon-bell-55",
    component: <AddDeviceType />,
    layout: "/admin",
  },

  //devices routes start here
  {
    path: "configurations/device/list-device",
    name: "Device List",
    icon: "tim-icons icon-bell-55",
    component: <DeviceList />,
    layout: "/admin",
  },
  {
    path: "configurations/device/add-device/:deviceId?",
    name: "Add Device",
    icon: "tim-icons icon-bell-55",
    component: <AddDevice />,
    layout: "/admin",
  },

  //ems-devices routes starts here
  //ems-factor routes starts here
  {
    path: "configurations/ems-device-master/list-factor",
    name: "Ems Factor List",
    icon: "tim-icons icon-bell-55",
    component: <EmsFactorList />,
    layout: "/admin",
  },

  //ems-method routes starts here
  {
    path: "/configurations/ems-device-master/list-method",
    name: "Ems Method List",
    icon: "tim-icons icon-bell-55",
    component: <EmsMethodList />,
    layout: "/admin",
  },
  

  //ems-device-type routes starts here
  {
    path: "/configurations/ems-device-master/list-ems-device-type",
    name: "Ems Device Type List",
    icon: "tim-icons icon-bell-55",
    component: <EmsDeviceTypeList />,
    layout: "/admin",
  },

  //ems-device-type routes starts here
  {
    path: "/configurations/ems-device-master/list-ems-device",
    name: "Ems Device List",
    icon: "tim-icons icon-bell-55",
    component: <EmsDeviceList />,
    layout: "/admin",
  },
  {
    path: "/configurations/ems-device-master/add-ems-device/:emsDeviceId?",
    name: "Add Ems Device",
    icon: "tim-icons icon-bell-55",
    component: <AddEmsDevice />,
    layout: "/admin",
  },
  //ghg-factor routes starts here
  {
    path: "/configurations/ghg-master/list-ghg-factor",
    name: "Ghg Factor List",
    icon: "tim-icons icon-bell-55",
    component: <GhgFactorList />,
    layout: "/admin",
  },
  {
    path: "/configurations/ghg-master/add-ghg-factor",
    name: "Add Ghg Factor",
    icon: "tim-icons icon-bell-55",
    component: <AddGhgFactor />,
    layout: "/admin",
  },

  //ghg-method routes starts here
  {
    path: "/configurations/ghg-master/list-ghg-method",
    name: "Ghg Method List",
    icon: "tim-icons icon-bell-55",
    component: <GhgMethodList />,
    layout: "/admin",
  },
  {
    path: "/configurations/ghg-master/add-ghg-method",
    name: "Add Ghg Method",
    icon: "tim-icons icon-bell-55",
    component: <AddGhgMethod />,
    layout: "/admin",
  },
  //ghg-device-type routes starts here
  {
    path: "/configurations/device/list-ghg-device-type",
    name: "Ghg Device Type List",
    icon: "tim-icons icon-bell-55",
    component: <GhgDeviceTypeList />,
    layout: "/admin",
  },
  //gwp-potential routes starts here
  {
    path: "/configurations/ghg-master/list-gwp-potential",
    name: "Gwp Potential List",
    icon: "tim-icons icon-bell-55",
    component: <GwpPotentialList />,
    layout: "/admin",
  },

  //ghg-device routes starts here
  {
    path: "/configurations/device/list-ghg-device",
    name: "Ghg Device List",
    icon: "tim-icons icon-bell-55",
    component: <GhgDeviceList />,
    layout: "/admin",
  },
  {
    path: "/configurations/device/add-ghg-device/:ghgDeviceId?",
    name: "Add Ghg Device",
    icon: "tim-icons icon-bell-55",
    component: <AddGhgDevice />,
    layout: "/admin",
  },
  //amt-device routes starts here
  {
    path: "/configurations/amt-config/list-amt-device",
    name: "Add Ghg Device",
    icon: "tim-icons icon-bell-55",
    component: <AmtDeviceList />,
    layout: "/admin",
  },
  {
    path: "/configurations/amt-config/amt-device-edit/:deviceId?",
    name: "Add Amt Device",
    icon: "tim-icons icon-bell-55",
    component: <AddAmtDevice />,
    layout: "/admin",
  },
  //amt-exceedance routes starts here
  {
    path: "/configurations/amt-config/list-amt-exceedance",
    name: "Amt Exceedance List",
    icon: "tim-icons icon-bell-55",
    component: <AmtExceedanceList />,
    layout: "/admin",
  },
  {
    path: "/configurations/amt-config/amt-exceedance-edit",
    name: "Add Amt Device",
    icon: "tim-icons icon-bell-55",
    component: <AddAmtExceedance />,
    layout: "/admin",
  },
  //physical-properties routes starts here
  {
    path: "/configurations/composition/list-physical-properties",
    name: "Physical Properties List",
    icon: "tim-icons icon-bell-55",
    component: <PhysicalPropertiesList />,
    layout: "/admin",
  },
  {
    path: "/configurations/composition/add-physical-properties",
    name: "Add Physical Properties",
    icon: "tim-icons icon-bell-55",
    component: <AddPhysicalProperties />,
    layout: "/admin",
  },
  //liquid-composition routes starts here
  {
    path: "/configurations/composition/list-liquid-composition",
    name: "Liquid Composition List",
    icon: "tim-icons icon-bell-55",
    component: <LiquidCompositionList />,
    layout: "/admin",
  },
  {
    path: "/configurations/composition/add-liquid-composition",
    name: "Add Liquid Composition",
    icon: "tim-icons icon-bell-55",
    component: <AddLiquidComposition />,
    layout: "/admin",
  },
  // pollutant routes starts here 
  {
    path: "/configurations/composition/list-pollutant-limit",
    name: "Pollutant Limit List",
    icon: "tim-icons icon-bell-55",
    component: <PollutantLimitList />,
    layout: "/admin",
  },
  {
    path: "/configurations/composition/add-pollutant-limit",
    name: "Add Pollutant Limit",
    icon: "tim-icons icon-bell-55",
    component: <AddPollutantLimit />,
    layout: "/admin",
  },

  //equipment routes starts here

  {
    path: "/configurations/equipment/list-equipment-data",
    name: "Equipment List",
    icon: "tim-icons icon-bell-55",
    component: <EquipmentList />,
    layout: "/admin",
  },
  {
    path: "/configurations/equipment/add-equipment-data",
    name: "Add Equipment",
    icon: "tim-icons icon-bell-55",
    component: <AddEquipment />,
    layout: "/admin",
  },
  //equipment clasification routes starts here

  {
    path: "/configurations/equipment/list-equipment-classification",
    name: "Equipment Classification List",
    icon: "tim-icons icon-bell-55",
    component: <EquipmentClassificationList />,
    layout: "/admin",
  },
  {
    path: "/configurations/equipment/add-equipment-classification/:typeId?",
    name: "Add Equipment Classification",
    icon: "tim-icons icon-bell-55",
    component: <AddEquipmentClassification />,
    layout: "/admin",
  },

  //pipes routes starts here 
  // pipe-fitting routes starts here 
  {
    path: "/configurations/pipe/fitting-list",
    name: "Pipe Fitting List",
    icon: "tim-icons icon-bell-55",
    component: <PipeFittingList />,
    layout: "/admin",
  },
  {
    path: "/configurations/pipe/add-pipe-fitting",
    name: "Add Pipe Fitting",
    icon: "tim-icons icon-bell-55",
    component: <AddPipeFitting />,
    layout: "/admin",
  },

  // pipe-frication-factor routes starts here 
  {
    path: "/configurations/pipe/list-pipe-friction-factor",
    name: "Pipe Friction Factor List",
    icon: "tim-icons icon-bell-55",
    component: <PipeFrictionFactorList />,
    layout: "/admin",
  },
  {
    path: "/configurations/pipe/add-pipe-friction-factor",
    name: "AddPipe Friction Factor",
    icon: "tim-icons icon-bell-55",
    component: <AddPipeFrictionFactor />,
    layout: "/admin",
  },
  //flow coefficient routes starts here 
  {
    path: "/configurations/pipe/list-coefficient",
    name: "Pipe Friction Factor List",
    icon: "tim-icons icon-bell-55",
    component: <FlowCoefficientList />,
    layout: "/admin",
  },
  {
    path: "/configurations/pipe/add-coefficient",
    name: "AddPipe Friction Factor",
    icon: "tim-icons icon-bell-55",
    component: <AddFlowCoefficient />,
    layout: "/admin",
  },

   // system-plan routes starts here 
  // flaring-plan routes starts here 
  {
    path: "/configurations/system-plan/list-flaring-plan",
    name: "Flaring Plan List",
    icon: "tim-icons icon-bell-55",
    component: <FlaringPlanList />,
    layout: "/admin",
  },
  {
    path: "/configurations/system-plan/add-flaring-plan",
    name: "Add Flaring Plan",
    icon: "tim-icons icon-bell-55",
    component: <AddFlaringPlan />,
    layout: "/admin",
  },
 // emission-plan routes starte here 
  {
    path: "/configurations/system-plan/list-emission-plan",
    name: "Emission Plan List",
    icon: "tim-icons icon-bell-55",
    component: <EmissionPlanList />,
    layout: "/admin",
  },
  {
    path: "/configurations/system-plan/add-emission-plan",
    name: "Add Emission Plan",
    icon: "tim-icons icon-bell-55",
    component: <AddEmissionPlan />,
    layout: "/admin",
  },

  // company-master routes starts here
  // tag-master routes start here 
  {
    path: "/configurations/master/list-tags-master",
    name: "Tags Master List",
    icon: "tim-icons icon-bell-55",
    component: <TagsMasterList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-tags-master/:id?",
    name: "Add Tag Master V One",
    icon: "tim-icons icon-bell-55",
    component: <AddTagMasterVOne />,
    layout: "/admin",
  },
  {
    path: "configurations/master/add-tags-master2",
    name: "Add Tag Master V Two",
    icon: "tim-icons icon-bell-55",
    component: <AddTagMasterVTwo />,
    layout: "/admin",
  },

  // code-master routes start here 
  {
    path: "/configurations/master/list-code-master",
    name: "Code Master List",
    icon: "tim-icons icon-bell-55",
    component: <CodeMasterList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-code-master",
    name: "Add Code Master",
    icon: "tim-icons icon-bell-55",
    component: <AddCodeMaster />,
    layout: "/admin",
  },
  //uom routes start here
  {
    path: "/configurations/master/list-uom",
    name: "Uom List",
    icon: "tim-icons icon-bell-55",
    component: <UomList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-uom/:uomId?",
    name: "Add Uom",
    icon: "tim-icons icon-bell-55",
    component: <AddUom />,
    layout: "/admin",
  },
  //uom-conversion routes start here 
  {
    path: "/configurations/master/list-uom-conversion",
    name: "Uom Conversion List",
    icon: "tim-icons icon-bell-55",
    component: <UomConversionList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-uom-conversion/:id?",
    name: "Add Conversion Uom",
    icon: "tim-icons icon-bell-55",
    component: <AddUomConversion />,
    layout: "/admin",
  },

// status routes start here 
  {
    path: "/configurations/master/list-status",
    name: "Status List",
    icon: "tim-icons icon-bell-55",
    component: <StatusList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-status",
    name: "Add Status",
    icon: "tim-icons icon-bell-55",
    component: <AddStatus />,
    layout: "/admin",
  },

// annual-prices routes start here 
  {
    path: "/configurations/master/list-annual-price",
    name: "Annual Price List",
    icon: "tim-icons icon-bell-55",
    component: <AnnualPriceList />,
    layout: "/admin",
  },
  {
    path: "/configurations/master/add-annual-price",
    name: "Add Annual Price",
    icon: "tim-icons icon-bell-55",
    component: <AddAnnualPrice />,
    layout: "/admin",
  },

  //defualt setting routes start here 
  {
    path: "/configurations/ems-defaults",
    name: "System Defaults",
    icon: "tim-icons icon-bell-55",
    component: <SystemDefaults />,
    layout: "/admin",
  },

  //notification routes starts here 
//user-notification routes start here 
  {
    path: "/configurations/notification/list-user-configuration",
    name: "UsersConfigurationList",
    icon: "tim-icons icon-bell-55",
    component: <UsersConfigurationList />,
    layout: "/admin",
  },
  {
    path: "/configurations/notification/add-user-configuration",
    name: "Add Users Configuration",
    icon: "tim-icons icon-bell-55",
    component: <AddUsersConfiguration />,
    layout: "/admin",
  },

// subscribe-notification start here 
  {
    path: "/configurations/notification/list-subscriber-configuration",
    name: "Subscriber Configuration List",
    icon: "tim-icons icon-bell-55",
    component: <SubscriberConfigurationList />,
    layout: "/admin",
  },
  {
    path: "/configurations/notification/add-subscriber-configuration/:id?",
    name: "Add Subscribe Configuration",
    icon: "tim-icons icon-bell-55",
    component: <AddSubscribeConfiguration />,
    layout: "/admin",
  },

//rule-notification routes start here 
  {
    path: "/configurations/notification/list-rule-configuration",
    name: "Rule Configuration List",
    icon: "tim-icons icon-bell-55",
    component: <RuleConfigurationList />,
    layout: "/admin",
  },
  {
    path: "/configurations/notification/add-rule-configuration/:id?",
    name: "Add Rule Configuration",
    icon: "tim-icons icon-bell-55",
    component: <AddRuleConfiguration />,
    layout: "/admin",
  },

  // file upload componets routes start here
  {
    path: "/data-import/file-upload",
    name: "Data Import",
    icon: "tim-icons icon-bell-55",
    component: <FileUploadComponent />,
    layout: "/admin",
  },
  {
    path: "/data-import/finalize",
    name: "Data Import",
    icon: "tim-icons icon-bell-55",
    component: <DataImportComponent />,
    layout: "/admin",
  },

  // project routes starts here 
  // projects master routes 
  {
    path: "/project/list-project-master",
    name: "List Project Master",
    icon: "tim-icons icon-bell-55",
    component: <ListProjectMaster />,
    layout: "/admin",
  },
  {
    path: "/project/add-project-master/:projectId?",
    name: "Add Project Master",
    icon: "tim-icons icon-bell-55",
    component: <AddProjectMaster />,
    layout: "/admin",
  },

  // project transaction routes start here 
  {
    path: "/project/list-project-transaction",
    name: "List Project Transaction",
    icon: "tim-icons icon-bell-55",
    component: <ListProjectTransaction />,
    layout: "/admin",
  },
  {
    path: "/project/add-project-transaction/:id?",
    name: "Add Project Transaction",
    icon: "tim-icons icon-bell-55",
    component: <AddProjectTransaction />,
    layout: "/admin",
  },

  //project category routes start here 
  {
    path: "/project/list-project-category",
    name: "List Project Category",
    icon: "tim-icons icon-bell-55",
    component: <ListProjectCategory />,
    layout: "/admin",
  },
  

  // project type routes start here 
  {
    path: "/project/list-project-type",
    name: "List Project Type",
    icon: "tim-icons icon-bell-55",
    component: <ListProjectType />,
    layout: "/admin",
  },

  // project status routes start here 
  {
    path: "/project/list-project-status",
    name: "List Project Status",
    icon: "tim-icons icon-bell-55",
    component: <ListProjectStatus />,
    layout: "/admin",
  }, 

   // help routes start here 
   {
    path: "/help",
    name: "Help",
    icon: "tim-icons icon-bell-55",
    component: <HelpComponent />,
    layout: "/admin",
  }, 

];
export default routes;