import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const TagsMasterExportComponent = ({ data, columns, fileName, headerData = [], title = '' }) => {
    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: 'A1' });

        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columns.length - 1 } }];

        const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
        XLSX.utils.sheet_add_aoa(worksheet, [[headerString]], { origin: 'A2' });

        worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: columns.length - 1 } });

        XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: false });

        const wscols = columns.map(() => ({ wch: 20 }));
        worksheet['!cols'] = wscols;

        // Define header styles
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } },
            alignment: { horizontal: "center" },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
            }
        };

        // Apply header styles
        columns.forEach((col, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 2, c: index });
            if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
            worksheet[cellAddress].s = headerStyle;
        });

        // Apply right alignment to specified columns
        const rightAlignCols = ['Min', 'Max', 'Default Value', 'Schedule Hours'];
        rightAlignCols.forEach(colName => {
            const colIndex = columns.findIndex(col => col.name === colName);
            if (colIndex !== -1) {
                worksheet['!cols'][colIndex] = { wch: 20, alignment: { horizontal: 'right' } };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'A3'
        });

        // Get the page width
        const pageWidth = doc.internal.pageSize.getWidth();

        // Add header information
        const titleFontSize = 12;
        const titleWidth = doc.getTextWidth(title) * titleFontSize / 6;
        const titleX = (pageWidth - titleWidth) / 2;

        doc.setFontSize(titleFontSize);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text(title, titleX, 30);

        if (headerData) {
            let headerX = 40;
            let headerY = 50;
            headerData.forEach(item => {
                doc.setFontSize(10);
                doc.text(`${item.label}: ${item.value}`, headerX, headerY);
                headerX += 170;
            });
        }

        const convertToCSVFormat = (objArray) => {
            const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
            let csvContent = [];
            const row = Object.keys(array[0]);

            csvContent.push(row);

            for (let i = 0; i < array.length; i++) {
                let line = [];
                for (let index in array[i]) {
                    line.push(array[i][index]);
                }
                csvContent.push(line);
            }

            return csvContent;
        };

        const tableContent = convertToCSVFormat(data);
        const tableColumn = tableContent[0];
        const tableRows = tableContent.slice(1);

        // Determine column alignment based on data type
        const columnStyles = {};
        tableRows[0].forEach((cell, index) => {
                if(['LIMS Composition','LIMS Property','Schedule Hours'].includes(columns[index].name)) {
                  columnStyles[index] = { cellWidth: 'auto', halign: 'left' };    
                }else if(['Min','Max','Default Value'].includes(columns[index].name)){
                  columnStyles[index] = { cellWidth: 'auto', halign: 'right' }; // Align specified columns to right   
                }else{
                    columnStyles[index] = { cellWidth: 'auto', halign: 'left' };    
                }
        });

        doc.autoTable({
            startY: headerData ? 80 : 40,
            head: [tableColumn],
            body: tableRows,
            styles: { fontSize: 8, cellWidth: 'wrap' },
            headStyles: { fillColor: [22, 160, 133], fontSize: 8, textColor: [255, 255, 255], lineWidth: 0.1, lineColor: [222, 226, 230] },
            theme: 'grid',
            margin: { top: 20 },
            bodyStyles: { valign: 'top', textColor: [0, 0, 0], lineWidth: 0.1, lineColor: [222, 226, 230] },
            columnStyles: columnStyles
        });

        doc.save(`${fileName}.pdf`);
    };

    const convertToCSV = (objArray, title, headerData) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
    
        // Add title if provided
        if (title) {
            str += `${title}\r\n`;
        }
    
        // Add header data if provided
        if (headerData) {
            const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
            str += `${headerString}\r\n`;
            str += '\r\n';
        }
    
        // Get the headers (keys from the first object in the array)
        const row = Object.keys(array[0]);
    
        // Add headers to CSV
        str += row.join(',') + '\r\n';
    
        // Add data rows
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';
    
                let value = array[i][index];
    
                // Check if the value contains a comma or double quotes and wrap in quotes if necessary
                if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                    // Escape double quotes by doubling them
                    value = `"${value.replace(/"/g, '""')}"`;
                }
    
                line += value;
            }
            str += line + '\r\n';
        }
    
        return str;
    };

    const exportToCSV = () => {
        const csv = convertToCSV(data, title, headerData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${fileName}.csv`);
    };

    return (
        <div className="export-buttons">
            <button className="btn btn-sm btn-primary mr-2" onClick={exportToCSV}>
                Export to CSV
            </button>
            <button className="btn btn-sm btn-success mr-2" onClick={exportToExcel}>
                Export to Excel
            </button>
            <button className="btn btn-sm btn-danger" onClick={exportToPDF}>
                Export to PDF
            </button>
        </div>
    );
};

export default TagsMasterExportComponent;
