import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddGhgDevice from './AddGhgDevice'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import GhgDeviceListExportComponent from '../../../../common/exports/GhgDeviceListExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function GhgDeviceList() {
    const [ghgDeviceData, setGhgDeviceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selecteddeviceId, setSelecteddeviceId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchGhgDeviceData();
    }, []);

    const fetchGhgDeviceData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/device/ghg-device/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGhgDeviceData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Source data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/device/add-ghg-device/${id}`);
    };

    const handleDelete = async (deviceId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Ghg Factor: ${deviceId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/device/ghg-device/delete/${deviceId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchGhgDeviceData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = ghgDeviceData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );

        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered.push({
                deviceId: '',
                materialState: '',
                sourceId: '',
                ghgMethodId: '',
                ghgMethodComments: '',
                efUncertaintyPercentage: '',
                afUncertaintyPercentage: '',
                scope: '',
                measurementType: '',
                ghg: '',
                ghgKey: '',
                ghGuom: '',
                status: '',

            });
        }
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Source ID': item.deviceId,
            'Material State': item.materialState,
            'Source Type ID': item.sourceId,
            'GHG Method ID': item.ghgMethodId,
            'GHG Method Comments': item.ghgMethodComments,
            'EF Uncertainty Percentage': formatWithIndianThousandsSeparatorForExportData(item.efUncertaintyPercentage ? item.efUncertaintyPercentage.toFixed(0) : item.efUncertaintyPercentage),
            'AF Uncertainty Percentage': formatWithIndianThousandsSeparatorForExportData(item.afUncertaintyPercentage ? item.afUncertaintyPercentage.toFixed(0) : item.afUncertaintyPercentage),
            'Scope': formatWithIndianThousandsSeparatorForExportData(item.scope),
            'Measurement Type': item.measurementType,
            'GHG': item.ghg,
            'GHG Key': item.ghgKey,
            'GHG UoM': item.ghGuom,
            'Status': item.status
        }));

        setExportFilteredData(exportData);

    }, [search, ghgDeviceData]);

    const columns = [
        { name: 'Source ID', selector: row => row.deviceId, sortable: true, resizable: true },
        { name: 'Material State', selector: row => row.materialState, sortable: true, },
        { name: 'Source Type ID', selector: row => row.sourceId, sortable: true, },
        { name: 'GHG Method ID', selector: row => row.ghgMethodId, sortable: true, },
        { name: 'GHG Method Comments', selector: row => row.ghgMethodComments, sortable: true, },
        {
            name: 'EF Uncertainty Percentage',
            selector: row => row.efUncertaintyPercentage,
            cell: row => formatWithIndianThousandsSeparator(row.efUncertaintyPercentage ? row.efUncertaintyPercentage.toFixed(0) : row.efUncertaintyPercentage),
            sortable: true,
        },
        {
            name: 'AF Uncertainty Percentage',
            selector: row => row.afUncertaintyPercentage,
            cell: row => formatWithIndianThousandsSeparator(row.afUncertaintyPercentage ? row.afUncertaintyPercentage.toFixed(0) : row.afUncertaintyPercentage),
            sortable: true,
        },
        {
            name: 'Scope',
            selector: row => row.scope,
            cell: row => formatWithIndianThousandsSeparator(row.scope),
            sortable: true,
        },
        { name: 'Measurement Type', selector: row => row.measurementType, sortable: true, },
        { name: 'GHG', selector: row => row.ghg, sortable: true, },
        { name: 'GHG Key', selector: row => row.ghgKey, sortable: true, },
        { name: 'GHG UoM', selector: row => row.ghGuom, sortable: true, },
        { name: 'Status', selector: row => row.status, sortable: true, },
        {
            name: 'Edit',
            width: '75px',
            cell: row => row.id ? (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.id)}
                    style={{ cursor: 'pointer' }}
                />
            ) : null
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => row.id ? (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            ) : null
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Ghg_Source_${new Date().toLocaleDateString()}`;

    const title = "GHG Sources Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>GHG Sources</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-GHG</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">GHG Source</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/device/add-ghg-device" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <GhgDeviceListExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}


