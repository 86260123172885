import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import AddAnnualPrice from './AddAnnualPrice'; // Adjust the path as necessary
import AnnualPriceService from '../services/AnnualPriceService'
import ExportComponent from '../../../../common/ExportComponent';
import AnnualPriceListExportComponent from '../../../../common/exports/AnnualPriceListExportComponent';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function AnnualPriceList() {
    const [annualPrices, setAnnualPrices] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedAnnualPrice, setSelectedAnnualPrice] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);


    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchAnnualPrices();
    }, []);

    const fetchAnnualPrices = async () => {
        setIsLoading(true);
        try {
            const response = await AnnualPriceService.getAllAnnualPrices();
            setAnnualPrices(response);
            setFilteredData(response);
        } catch (error) {
            console.error('Failed to fetch annual prices:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (annualPrice) => {
        setSelectedAnnualPrice(annualPrice);
        setShowModal(true);
    };

    const handleDelete = async (year) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Annual Price for year: ${year}?`);
        if (confirmDelete) {
            try {
                await AnnualPriceService.deleteAnnualPrice(year);
                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchAnnualPrices();
            } catch (error) {
                console.error('Failed to delete annual price:', error);
            }
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = annualPrices.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Year': item.year,
            'Cost Per MMBTU (USD)': formatWithIndianThousandsSeparatorForExportData(item.costPerMMBtu ? item.costPerMMBtu.toFixed(2) : item.costPerMMBtu),
            'Cost Per BBL (USD)': formatWithIndianThousandsSeparatorForExportData(item.costPerBBL ? item.costPerBBL.toFixed(2) : item.costPerBBL),
        }));
        setExportFilteredData(exportData);

    }, [search, annualPrices]);

    const columns = [
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Cost Per MMBTU (USD)',
            selector: row => row.costPerMMBtu,
            cell: row => formatWithIndianThousandsSeparator(row.costPerMMBtu ? row.costPerMMBtu.toFixed(2) : row.costPerMMBtu),
            sortable: true
        },
        {
            name: 'Cost Per BBL (USD)',
            selector: row => row.costPerBBL,
            cell: row => formatWithIndianThousandsSeparator(row.costPerBBL ? row.costPerBBL.toFixed(2) : row.costPerBBL),
            sortable: true
        },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.year)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Annual_Price_${new Date().toLocaleDateString()}`;

    const title = "Annual price Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Annual Prices</h1>
                <ul>
                    <li>Operational Data</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Annual Price List</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Button className="btn btn-primary" onClick={() => setShowModal(true)}>
                                        Add New Record
                                    </Button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <AnnualPriceListExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
            <AddAnnualPrice
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                fetchAnnualPrices={fetchAnnualPrices}
                annualPrice={selectedAnnualPrice}
            />
        </div>
    );
}
