import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './TopNav.css'
const TopNav = ({ toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  // List of paths where CustomDatePicker should be shown
  const datePickerPaths = ["/admin/flaring", "/admin/emissions", "/admin/greenhouse", "/admin/ammnet"];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  const handleLogoClick = () => {
    navigate('/admin/flaring'); // Navigate to the specified route
  };

  const getDashboardLabel = () => {
    if (location.pathname === "/admin/flaring") {
        return "Flaring in MMscf";
    } else if (location.pathname === "/admin/emissions") {
        return "Emissions in kg";
    } else if (location.pathname === "/admin/greenhouse") {
        return "GHG in metric tonnes";
    } else if (location.pathname === "/admin/ammnet") {
        return "Emissions in  μg/m³";
    } else {
        return ""; // Default to empty string if the path doesn't match any
    }
};

  return (
    <div className="main-header">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        {/* <img src={require('../../assets/images/logo.png')} alt="logo" /> */}
        <img src={require('../../assets/images/green6.png')} alt="logo" />
      </div>
      <div className="menu-toggle" onClick={toggleSidebar}>
        <div />
        <div />
        <div />
      </div>
      
      <div className='mb-4 top-nav-dashboard-label'>
        <h6>{getDashboardLabel()} </h6>
      </div>
      <div style={{ margin: 'auto' }} />
      <div className="header-part-right">
        {datePickerPaths.includes(location.pathname) && <CustomDatePicker />}
        {/* <div className="dropdown">
          <div className="badge-top-container" role="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="badge badge-primary">3</span>
            <i className="i-Bell text-muted header-icon" />
          </div>
          <div className="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
            Notification content
          </div>
        </div> */}
        
        <div className="dropdown">
          <div className="user col align-self-end">
            <FontAwesomeIcon icon={faUser} id="userDropdown" alt="user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size="2x" style={{ cursor:'pointer' }} />
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <div className="dropdown-header">
                <i className="i-Lock-User mr-1" /> Green Vue
              </div>
              {/* <a className="dropdown-item">Account settings</a>
              <a className="dropdown-item">Billing history</a> */}
              <a className="dropdown-item" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Sign out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
