import React, { useEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import './EmissionsByReason.css'; // Ensure to create and import your CSS file

const EmissionsByReason = ({ areaId, facilityId, date, emissionByReasons }) => {
  const chartRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (emissionByReasons && emissionByReasons.length > 0) {
      renderSunburstChart(emissionByReasons);
    }
  }, [emissionByReasons]);

  useEffect(() => {
    return () => {
      if (rootRef.current) {
        rootRef.current.dispose();
      }
    };
  }, []);

  const renderSunburstChart = (emissions) => {
    if (rootRef.current) {
      rootRef.current.dispose();
    }

    const root = am5.Root.new(chartRef.current);
    rootRef.current = root;
    root.setThemes([am5themes_Animated.new(root)]);

    // Remove the watermark
    root._logo.dispose();

    const series = root.container.children.push(
      am5hierarchy.Sunburst.new(root, {
        singleBranchOnly: false,
        downDepth: 3,
        initialDepth: 3,
        topDepth: 0,
        innerRadius: am5.percent(10),
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        colors: am5.ColorSet.new(root, {
          colors: ['#0A7029', '#FEDE00', '#C8DF52', '#b57bee', '#116530', '#21B6A8', '#A3EBB1', '#18A558', '#145DA0', '#0C2D48', '#2E8BC0', '#B1D4E0', '#B1D4E0'],
          passOptions: {
            lightness: 0.1,
          },
        }),
      })
    );

    const chartData = convertToSunburstData(emissions);
    series.data.setAll(chartData);

    // Add labels to the segments
    series.slices.template.set("tooltipText", "{name}: {value}");
    series.labels.template.setAll({
      text: "{name}",
      fill: am5.color(0x000000),
      centerX: am5.percent(50),
      centerY: am5.percent(50),
    });

    series.appear(1000, 100);
  };

  // const convertToSunburstData = (emissions) => {
  //   // Group emissions by categoryId
  //   const groupedByCategory = emissions.reduce((acc, emission) => {
  //     const { categoryId } = emission;
  //     if (!acc[categoryId]) {
  //       acc[categoryId] = [];
  //     }
  //     acc[categoryId].push(emission);
  //     return acc;
  //   }, {});

  //   // Create the data structure for the sunburst chart
  //   const data = {
  //     name: 'Emissions',
  //     children: Object.keys(groupedByCategory).map(categoryId => ({
  //       name: categoryId, // Inner circle representing categoryId
  //       children: groupedByCategory[categoryId].map((e) => ({
  //         name: `${e.reason} - (${e.percentage}%)`,
  //         children: e.pollutants.map((p) => ({
  //           name: p.key,
  //           value: p.value
  //         }))
  //       }))
  //     }))
  //   };

  //   return [data];
  // };


  const convertToSunburstData = (emissions) => {
    // Group emissions by categoryId
    const groupedByCategory = emissions.reduce((acc, emission) => {
      const { categoryId } = emission;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }
      acc[categoryId].push(emission);
      return acc;
    }, {});
  
    // Create the data structure for the sunburst chart
    const data = {
      name: 'Emissions',
      children: Object.keys(groupedByCategory).map(categoryId => ({
        name: categoryId, // Inner circle representing categoryId
        children: groupedByCategory[categoryId].map((e) => ({
          name: `${e.reason} - (${e.percentage}%)`,
          children: e.pollutants.map((p) => ({
            name: formatPollutantLabel(p.key), // Format pollutant names here
            value: p.value
          }))
        }))
      }))
    };
  
    return [data];
  };
  
  // Function to format pollutant labels
  const formatPollutantLabel = (pollutantKey) => {
    switch (pollutantKey) {
      case 'NOX':
        return 'NOx';
      case 'SOX':
        return 'SOx';
      default:
        return pollutantKey;
    }
  };

  
  return (
    <div className="emission-by-reason">
      <div className='card'>
        <div className='card-header'>
          Emissions By Reasons
        </div>
        <div className='card-body'>
          {emissionByReasons.length === 0 ? (
            <div className="alert alert-info">No data for emissions by reason for selected date!</div>
          ) : (
            <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmissionsByReason;
