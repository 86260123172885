import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddHeader from './AddHeader'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function HeaderList() {
    const [headerData, setHeaderData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedHeaderId, setSelectedHeaderId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchHeaderData();
    }, []);

    const fetchHeaderData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/header/all`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setHeaderData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/ems-master/add-header?headerId=${id}`);
    };

    const handleDelete = async (headerId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete header: ${headerId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/ems-master/header/delete/${headerId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchHeaderData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedHeaderId(null);
        fetchHeaderData(); // Refresh data after adding or editing
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = headerData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Header ID': item.headerId,
            'Header Name': item.headerName,
            'Facility ID': item.facilityId,
            'Header Type': item.headerType,
            'Header Classification': item.headerClassification,
            'Stack ID': item.stackId,
            'Molecular Weight': formatWithIndianThousandsSeparatorForExportData(item.molecularWeight ? item.molecularWeight.toFixed(1) : item.molecularWeight),
            'BTUSCF': formatWithIndianThousandsSeparatorForExportData(item.btuscf ? item.btuscf.toFixed(0) : item.btuscf),
            'Recovery Valve Number': formatWithIndianThousandsSeparatorForExportData(item.recoveryValveNumber),  
            'Recovery Valve Type': formatWithIndianThousandsSeparatorForExportData(item.recoveryValveType),
            'Recovery Valve Set Point': formatWithIndianThousandsSeparatorForExportData(item.recoveryValveSetPoint ? item.recoveryValveSetPoint.toFixed(0) : item.recoveryValveSetPoint),
            'Min Unaccounted Fixed': formatWithIndianThousandsSeparatorForExportData(item.minUnaccountedFixed ? item.minUnaccountedFixed.toFixed(0) : item.minUnaccountedFixed),
            'Unaccounted UoM': item.unAccountedUOM,
            'Recycle Max Capacity': item.recycleMaxCapacity,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, headerData]);

    const columns = [
        { name: 'Header ID', selector: row => row.headerId, sortable: true },
        { name: 'Header Name', selector: row => row.headerName, sortable: true },
        { name: 'Facility ID', selector: row => row.facilityId, sortable: true },
        { name: 'Header Type', selector: row => row.headerType, sortable: true },
        { name: 'Header Classification', selector: row => row.headerClassification, sortable: true },
        { name: 'Stack ID', selector: row => row.stackId, sortable: true },
        {
            name: 'Molecular Weight',
            selector: row => row.molecularWeight,
            cell: row => formatWithIndianThousandsSeparator(row.molecularWeight ? row.molecularWeight.toFixed(1) : row.molecularWeight),
            sortable: true
        },
        {
            name: 'BTUSCF',
            selector: row => row.btuscf,
            cell: row => formatWithIndianThousandsSeparator(row.btuscf ? row.btuscf.toFixed(0) : row.btuscf),
            sortable: true
        },
        {
            name: 'Recovery Valve Number',
            selector: row => row.recoveryValveNumber,
            cell: row => formatWithIndianThousandsSeparator(row.recoveryValveNumber),
            sortable: true
        },
        {
            name: 'Recovery Valve Type',
            selector: row => row.recoveryValveType,
            cell: row => formatWithIndianThousandsSeparator(row.recoveryValveType),
            sortable: true
        },
        {
            name: 'Recovery Valve Set Point',
            selector: row => row.recoveryValveSetPoint,
            cell: row => formatWithIndianThousandsSeparator(row.recoveryValveSetPoint ? row.recoveryValveSetPoint.toFixed(0) : row.recoveryValveSetPoint),
            sortable: true
        },
        {
            name: 'Min Unaccounted Fixed',
            selector: row => row.minUnaccountedFixed,
            cell: row => formatWithIndianThousandsSeparator(row.minUnaccountedFixed ? row.minUnaccountedFixed.toFixed(0) : row.minUnaccountedFixed),
            sortable: true
        },
        { name: 'Unaccounted UoM', selector: row => row.unAccountedUOM, sortable: true },
        { name: 'Recycle Max Capacity', selector: row => row.recycleMaxCapacity, sortable: true },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.headerId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.headerId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Headers_${new Date().toLocaleDateString()}`;

    const title = "Header Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Headers</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Flare Master</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Headers</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    {/* configurations/ems-master/add-header */}
                                    <Link to="/admin/configurations/ems-master/add-header" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}
