import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddEmissionPlan from './AddEmissionPlan'; // Adjust the path as necessary
import formatDateTime from '../../../../common/formatDateTime';
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function EmissionPlanList() {
    const [emissionPlanData, setEmissionPlanData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchEmissionPlanData();
    }, []);

    const fetchEmissionPlanData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/system-plan/emission-plan`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmissionPlanData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Device data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/system-plan/add-emission-plan?id=${id}`);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Emission Plan: ${id}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/system-plan/emission-plan/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchEmissionPlanData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = emissionPlanData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Facility ID': item.facilityId,
            'Plant': item.plantId,
            'Plan Type': item.planType,
            'Component ID': item.componentID,
            'Comp Type': item.compType,
            'Plan Date': item.planDate ? formatDateTime(item.planDate) : '',
            'Plan Monthly Emission': formatWithIndianThousandsSeparatorForExportData(item.planMonthlyEmission ? item.planMonthlyEmission.toFixed(0) : item.planMonthlyEmission),
            'Units': item.units,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, emissionPlanData]);

    const columns = [
        { name: 'Facility ID', selector: row => row.facilityId, sortable: true, resizable: true, pinned: 'left' },
        { name: 'Plant', selector: row => row.plantId, sortable: true, resizable: true },
        { name: 'Plan Type', selector: row => row.planType, sortable: true, resizable: true },
        { name: 'Component ID', selector: row => row.componentID, sortable: true, resizable: true },
        { name: 'Comp Type', selector: row => row.compType, sortable: true, resizable: true },
        {
            name: 'Plan Date', selector: row => row.planDate, sortable: true, resizable: true,
            cell: (row) => {
                return row ? formatDateTime(row.planDate) : '';
            }
        },
        {
            name: 'Plan Monthly Emission',
            selector: row => row.planMonthlyEmission,
            cell: row => formatWithIndianThousandsSeparator(row.planMonthlyEmission ? row.planMonthlyEmission.toFixed(0) : row.planMonthlyEmission),
            sortable: true,
            resizable: true
        },
        { name: 'Units', selector: row => row.units, sortable: true, resizable: true },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.id)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Emission_Plan_${new Date().toLocaleDateString()}`;

    const title = "Emission Plans Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Emission Plans</h1>
                <ul>
                    <li>Operational Data</li>
                    <li>Corporate Emission Plans</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Emission Plan</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/system-plan/add-emission-plan" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}



